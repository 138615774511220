import React from 'react';
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Loading from '../../../components/common/Loading';
import Modal from '../../../components/common/Modal';
import '../../../components/common/Relatorio.css'
import { IOnboardingRelatorio } from '../interfaces/IOnboarding';

interface TableDataProps {
  ficha: IOnboardingRelatorio[];
  loading: boolean;
  msgAlert: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
}

const TableData: React.FC<TableDataProps> = ({ ficha, loading, msgAlert, modalOpen, handleCloseModal }) => {
  const fixDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  }

  const handleDateTime = (date: string) => date ? (date.toString().substring(0, 10) === '0001-01-01' ? "" : fixDateTime(date.toString())) : "";

  return (
    <div className="responsive-table-container">
      {loading ? <Loading /> : ''}
      <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />

      <TableContainer component={Paper} sx={{ marginTop: 4 }}>
      <Table>
  <TableHead>
    <TableRow>
      <TableCell>Nome</TableCell>
      <TableCell>Código Imobiliar</TableCell>
      <TableCell>Tipo</TableCell>
      <TableCell>Template</TableCell>
      <TableCell>Gestor</TableCell>
      <TableCell>Código Imóvel</TableCell>
      <TableCell>Dados de Acesso</TableCell>
    </TableRow>
  </TableHead>
  <TableBody>
    {ficha.map((item, index) => (
      <TableRow key={index}>
        <TableCell>{item.ClientName}</TableCell>
        <TableCell>{item.CodPessoa}</TableCell>
        <TableCell>{item.TemplateName}</TableCell>
        <TableCell>{item.TemplateType}</TableCell>
        <TableCell>{item.ManagerName}</TableCell>
        <TableCell>{item.CodImovel}</TableCell>
        <TableCell>
            Data/Hora de Envio: {handleDateTime(item.Datahora_Envio)} <br />
            Data/Hora de Entregue: {handleDateTime(item.Datahora_Entregue)} <br />
            Data/Hora de Abertura: {handleDateTime(item.DataHora_Abertura)} <br />
            Data/Hora do Click: {handleDateTime(item.Datahora_Clicou)}
        </TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>

      </TableContainer>
    </div>
  );
};

export default TableData;
