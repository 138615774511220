import { AppBar, Toolbar, Typography, Button, Container, IconButton, Menu, MenuItem } from "@mui/material";
import { useContext, useState, useEffect } from 'react';
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import { AuthContext } from "../../contexts/auth";
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Link as RouterLink } from 'react-router-dom';

interface AppBarProps {
  open: boolean;
  toggleDrawer: () => void;
  pageTitle: string;
}

const Topbar: React.FC<AppBarProps> = ({ open, toggleDrawer, pageTitle }) => {
  const { logout, validaAmbiente } = useContext(AuthContext);
  const [ambiente, setAmbiente] = useState('');
  const [auth, setAuth] = useState(true);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleLogout = () => {
    logout();
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  useEffect(() => {
    const fetchStatus = async () => {
      try {
        let retorno = await validaAmbiente();
        setAmbiente(retorno.value.ambiente);
      } catch (err: any) {
        console.log(err);
      }
    };
    fetchStatus();
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        width: "calc(100% - 80px)",
        ...(open && {
          width: `calc(100% - ${sizeConfigs.sidebar.width})`,
        }),
        ml: sizeConfigs.sidebar.width,
        boxShadow: "unset",
        backgroundColor: colorConfigs.topbar.bg,
        color: colorConfigs.topbar.color
      }}
    >
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            marginRight: '36px'
          }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          sx={{width: "500px"}}
          variant="h6">
          {pageTitle}
        </Typography>

        <Container sx={{ display: "flex", justifyContent: "end", maxWidth: "inherit !important" }}>
          {ambiente == "Homologação" ? (
            <Typography variant="h6">
              {ambiente}
            </Typography>
          ) : null}

          {auth && (
            <>
              <div>
                <IconButton
                  size="large"
                  aria-label="usuario"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem component={RouterLink} to="/ti/alterarsenha">
                    Meu Perfil
                  </MenuItem>
                  <MenuItem onClick={handleLogout}>Sair</MenuItem>
                </Menu>
              </div>
            </>
          )}
        </Container>


      </Toolbar>
    </AppBar>
  );
};

export default Topbar;