import React, { useState, useContext, useEffect } from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

import './styles.css';
import { AuthContext } from "../../contexts/auth";
import { Backdrop, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

export function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'© '}
            {new Date().getFullYear()}
            {' Guarida Imóveis. Todos os direitos reservados. '}
            <Link color="inherit" href="https://gdocs.guarida.com.br/">
                GDocs - Guarida
            </Link>{' '}
            {'.'}
        </Typography>
    );
}

const theme = createTheme();

const LoginPage = () => {
    const { login, envioRecuperacaoSenha } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [msgAlert, setMsgAlert] = useState("");
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");

    const [loadingRecuperacao, setLoadingRecuperacao] = useState(false);
    const [emailRecuperacao, setEmailRecuperacao] = useState("");
    const [openRecuperacao, setOpenRecuperacao] = useState(false);
    const [backgroundImage, setBackgroundImage] = useState('');

    const imageUrls = [
        'https://storage.googleapis.com/storage_geral/imagens/logo/1.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/2.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/3.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/4.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/5.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/6.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/7.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/8.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/9.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/10.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/11.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/12.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/13.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/14.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/15.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/16.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/17.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/18.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/19.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/20.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/21.jpg',
        'https://storage.googleapis.com/storage_geral/imagens/logo/22.jpg'
      ];
    
      useEffect(() => {
        const randomIndex = Math.floor(Math.random() * imageUrls.length);
        setBackgroundImage(imageUrls[randomIndex]);
      }, []);

    const handleClickOpenRecuperacao = () => {
        setOpenRecuperacao(true);
    };

    const handleCloseRecuperacao = () => {
        setOpenRecuperacao(false);
    };

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);

        try {
            await login(email, password);
        } catch {
            setSeverity("error");
            setMsgAlert("E-mail ou senha inválidos");
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleClickRecuperacao = async (e: any) => {
        e.preventDefault();
        setLoadingRecuperacao(true);
        try {
            await envioRecuperacaoSenha(emailRecuperacao);
            setSeverity("success");
            setMsgAlert("Recuperação enviada, verifique seu e-mail");
            setOpen(true);
        } catch (e: any) {
            setSeverity("error");
            setMsgAlert("E-mail inválido");
            setOpen(true);
        } finally {
            setLoadingRecuperacao(false);
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === "success" ? "success" : "error"} sx={{ width: '100%' }}>
                    {msgAlert}
                </Alert>
            </Snackbar>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            my: 8,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            src="https://storage.googleapis.com/storage_geral/imagens/logo/logo.png"
                            alt="Logo"
                            style={{ width: '50%', height: 'auto' }}>
                        </img>

                        <Typography component="h1" variant="h5">
                            GDocs - Login
                        </Typography>
                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="E-mail"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                value={email} onChange={(e) => setEmail(e.target.value)}
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Senha"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                value={password} onChange={(e) => setPassword(e.target.value)}
                            />
                            {loading ? (<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                                <CircularProgress size={24} />
                            </Box>) : (
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Entrar
                                </Button>)}
                        </Box>
                        <Link onClick={handleClickOpenRecuperacao}>
                            <Typography>Esqueceu sua senha?</Typography>
                        </Link>

                        <>
                            <Dialog open={openRecuperacao} onClose={handleCloseRecuperacao}>
                                <DialogTitle>Recuperar Senha</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Para recuperar sua senha, insira seu e-mail abaixo:
                                    </DialogContentText>
                                    <Box component="form" noValidate onSubmit={handleClickRecuperacao} sx={{ mt: 1 }}>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="emailRecuperacao"
                                            label="E-mail"
                                            name="email"
                                            autoComplete="email"
                                            autoFocus
                                            value={emailRecuperacao} onChange={(e) => setEmailRecuperacao(e.target.value)}
                                        />

                                        <DialogActions>
                                            <Button onClick={handleCloseRecuperacao}>Cancelar</Button>
                                            <Button type="submit">Enviar</Button>
                                        </DialogActions>
                                        <Backdrop 
                                            open={loadingRecuperacao}
                                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                                        >
                                            <CircularProgress />
                                        </Backdrop>
                                    </Box>
                                </DialogContent>
                            </Dialog>
                        </>

                        <Grid container>
                        </Grid>
                        <Copyright sx={{ mt: 5 }} />
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>

    );
}

export default LoginPage; 