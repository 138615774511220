import React, { useState, useContext } from 'react';
import { Typography } from '@mui/material';
import { AuthContext } from '../../../contexts/auth';
import { IntegradorImoveis } from '../../../interfaces/ISite';
import Relatorio from '../../../components/common/Relatorio';

const RelatorioIntegradorPage: React.FC = () => {
    const [ficha, setFicha] = useState<IntegradorImoveis[]>([]);
    const { buscaListaIntegradorSite } = useContext(AuthContext);
    const { exportaListaIntegradorSite } = useContext(AuthContext);

    return (
        <div>
            <Typography variant="h6">Relatório Integrador</Typography>
            <Relatorio
                busca={buscaListaIntegradorSite}
                exporta={exportaListaIntegradorSite}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"Integrador"}
            />
        </div>
    );
};

export default RelatorioIntegradorPage;