import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useEffect } from "react";

interface FilterFormLeadsProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<HTMLSelectElement>) => void
    handleChangeDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
    formData: any;
}

const FilterFormLeads: React.FC<FilterFormLeadsProps> = ({ handleChange, handleSelectChange, handleChangeDate, formData }) => {
    const defaultDataDe = formData.dataInicial ?
        (formData.dataInicial.toISOString().substring(0, 10) === '0001-01-01' ?
            "" : formData.dataInicial.toISOString().substring(0, 10)
        ) : "";
    const defaultDataAte = formData.dataFinal ?
        (formData.dataFinal.toISOString().substring(0, 10) === '0001-01-01' ?
            "" : formData.dataFinal.toISOString().substring(0, 10)
        ) : "";

    const campoObrigatorio =
        (formData.dataInicial && formData.dataFinal && formData.dataFinal.toISOString().substring(0, 10) !== '0001-01-01') ||
        (!formData.dataInicial && formData.dataFinal) ||
        (formData.dataInicial && formData.dataInicial.toISOString().substring(0, 10) !== '0001-01-01' && !formData.dataFinal);

    return (
        <>
            <Grid container
                direction="row">
                <Grid item xs={12}>
                    <FormControl
                        sx={{
                            width: '100%',
                            minWidth: '100%',
                            marginTop: '8px',
                            marginRight: '8px',
                        }}>
                        <TextField
                            label="Código Imóvel"
                            name="codigo_imovel"
                            value={formData.codigo_imovel === "0" ? "" : formData.codigo_imovel}
                            onChange={handleChange}
                            variant="outlined"
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl
                        sx={{
                            width: '100%',
                            minWidth: '100%',
                            marginTop: '8px',
                            marginRight: '8px',
                        }}>
                        <FormHelperText sx={{ mb: 1 }}>Busca por período:</FormHelperText>

                        <TextField
                            sx={{ mb: 2 }}
                            label="De:"
                            type="date"
                            name="dataInicial"
                            value={defaultDataDe}
                            onChange={handleChangeDate}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required={campoObrigatorio}
                        />
                        <TextField
                            sx={{ mb: 1 }}
                            label="Até: "
                            type="date"
                            name="dataFinal"
                            value={defaultDataAte}
                            onChange={handleChangeDate}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required={campoObrigatorio}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6} sx={{ mt: 4 }}>
                    <Grid>
                        <FormControl
                            sx={{
                                width: '100%',
                                margin: '8px'
                            }}>
                            <InputLabel htmlFor="outlined-select">Selecione um Tipo</InputLabel>
                            <Select
                                variant="filled"
                                name="tipo"
                                value={formData["tipo"] || 0}
                                onChange={handleSelectChange}
                                inputProps={{
                                    name: 'tipo',
                                    id: 'tipo-select',
                                }}
                            >
                                <MenuItem value={0}>Todos</MenuItem>
                                <MenuItem value={1}>Agendamento de Visita</MenuItem>
                                <MenuItem value={2}>Contato WhatsApp</MenuItem>
                                <MenuItem value={3}>Interesse por Imóvel</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid>
                        <FormControl
                            sx={{
                                width: '100%',
                                margin: '8px'

                            }}>
                            <InputLabel htmlFor="outlined-select">Selecione uma área</InputLabel>
                            <Select
                                variant="filled"
                                name="negocio"
                                value={formData["negocio"] || 0}
                                onChange={handleSelectChange}
                                inputProps={{
                                    name: 'negocio',
                                    id: 'negocio-select',
                                }}
                            >
                                <MenuItem value={0}>Alugar</MenuItem>
                                <MenuItem value={1}>Vendas</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};


export default FilterFormLeads;