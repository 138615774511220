import React, { useState } from 'react';
import { AlertProps, Checkbox , Snackbar, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { BuscaPessoas } from '../../../interfaces/IBuscaIntegracoesBetalabs';
import { formatarData } from '../../../utils/formatUtils';
import Loading from '../../../components/common/Loading';
import '../../../components/common/Relatorio.css'
interface TableDataProps {
  ficha: BuscaPessoas[];
  loading: boolean;
  modalOpen: boolean;
  handleCloseModal: () => void;
}

const TableDataPessoas: React.FC<TableDataProps> = ({ ficha, loading }) => {
  const [severity, setSeverity] = useState('success');
  const [msgAlert, setMsgAlert] = useState('');
  const [loadingModal, setLoadingModal] = useState(false);
  const [open, setOpen] = useState(false);

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
          {msgAlert}
        </Alert>
      </Snackbar>
      <div className="responsive-table-container">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Código condomínio</TableCell>
              <TableCell>Número integração</TableCell>
              <TableCell>Tipo pessoa</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell>CPF/CNPJ</TableCell>
              <TableCell>Rg</TableCell>
              <TableCell>Data nascimento</TableCell>
              <TableCell>Gênero</TableCell>
              <TableCell>Telefone</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>E-mail enviado</TableCell>
              <TableCell>Data cadastro</TableCell>
              <TableCell>Erros</TableCell>
            </TableRow>
          </TableHead>
          {loading ? <Loading /> : ''}
          {loadingModal ? <Loading /> : ''}
          <TableBody>
            {ficha.map((item) => (
              <TableRow key={item?.Id}>
                <TableCell>{item.Codigo_condominio}</TableCell>
                <TableCell>{item.Numero_integracao}</TableCell>
                <TableCell>{item.Tipo_pessoa}</TableCell>
                <TableCell>{item.Nome}</TableCell>
                <TableCell>{item.CpfCnpj}</TableCell>
                <TableCell>{item.Rg}</TableCell>
                <TableCell>{formatarData(item.Data_nascimento)}</TableCell>
                <TableCell>{item.Genero}</TableCell>
                <TableCell>{item.Telefone}</TableCell>
                <TableCell>{item.Email}</TableCell>
                <TableCell>
                  <Checkbox checked={item.Email_enviado} disabled />
                </TableCell>
                <TableCell>{formatarData(item.Data_cadastro)}</TableCell>
                <TableCell>{item.Erros}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
export default TableDataPessoas;