import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";

interface FilterFormIntegradorProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<HTMLSelectElement>) => void
    formData: any;
}

const FilterFormIntegrador: React.FC<FilterFormIntegradorProps> = ({ handleChange, handleSelectChange, formData }) => {
    return (
        <>
            <FormControl
                sx={{
                    width: '40%',
                    minWidth: '30%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="Código Imóvel"
                    name="codigo_imovel"
                    value={formData["codigo_imovel"]}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>

            <FormControl
                sx={{
                    width: '40%',
                    minWidth: '30%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <InputLabel htmlFor="outlined-select">Selecione uma área</InputLabel>
                <Select
                    variant="filled"
                    name="negocio"
                    value={formData["negocio"] || 0}
                    onChange={handleSelectChange}
                    inputProps={{
                        name: 'negocio',
                        id: 'negocio-select',
                    }}
                >
                    <MenuItem value={0}>Alugar</MenuItem>
                    <MenuItem value={1}>Vendas</MenuItem>
                </Select>
            </FormControl>
            <FormControl
                sx={{
                    width: '20%',
                    minWidth: '20%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <InputLabel htmlFor="outlined-select">Selecione uma opção</InputLabel>
                <Select
                    variant="filled"
                    name="estado"
                    value={formData["estado"] || 0}
                    onChange={handleSelectChange}
                    inputProps={{
                        name: 'estado',
                        id: 'estado-select',
                    }}
                >
                    <MenuItem value={0}>Log de Erros</MenuItem>
                    <MenuItem value={1}>Log de Sucessos</MenuItem>
                    <MenuItem value={2}>Todos</MenuItem>
                </Select>
            </FormControl>
        </>
    );
};


export default FilterFormIntegrador;