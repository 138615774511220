import React, { useState, useEffect, useContext } from "react";
import { Button, Autocomplete, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Loading from '../../components/common/Loading';
import { AuthContext } from "../../contexts/auth";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ICadastroUsuario } from '../../interfaces/ICadastroUsuario';
import { useLocation, useNavigate } from 'react-router-dom';

interface ILiderUsuario {
    id: number;
    username: string;
}

interface IAutocompleteOption {
    label: string;
    id: string;
}

function CadastroUsuarioPage() {
    const location = useLocation();
    const currentUser = location.state?.currentUser;
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [acessoGDocs, setAcessoGDocs] = useState(true);
    const [idLiderTime, setIDLiderTime] = useState<number | undefined>(-1);
    const [ehLiderTime, setEhLiderTime] = useState(false);
    const [atualizarADBaseGdocs, setAtualizarADBaseGdocs] = useState(false);
    
    const [ativo, setAtivo] = useState(true);
    const [ramal, setRamal] = useState("");
    const [whatsPessoal, setWhatsPessoal] = useState("");

    const [confirmaSenha, setConfirmaSenha] = useState("");
    const [loading, setLoading] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const { cadastraUsuario, pegaPermissoes, buscaLiderTime } = useContext(AuthContext);
    const [selectedPermissions, setSelectedPermissions] = useState<any[]>([]);
    const [permissions, setPermissions] = useState<{ id: number; descricao: string; }[]>([]);
    const [lideres, setLideres] = useState<IAutocompleteOption[]>([]);
    const [selectedOptionsLideres, setSelectedOptionsLideres] = useState<string | null>(null);
    const [photo, setPhoto] = useState<string>("");

    useEffect(() => {
        const fetchPermissions = async () => {
            try {
                const permissionsData = await pegaPermissoes();
                setPermissions(permissionsData);
            } catch (error) {
                console.error('Erro ao buscar permissões', error);
            }
        };

        const fetchLider = async () => {
            try {
                const liderData = await buscaLiderTime();
                const lideres: ILiderUsuario[] = liderData;
                const autocompleteOptions: IAutocompleteOption[] = lideres.map((lider) => ({
                    label: lider.username,
                    id: lider.id.toString(),
                }));
    
                setLideres(autocompleteOptions);
            } catch (error) {
                console.error('Erro ao buscar permissões', error);
            }
        };

        fetchPermissions();
        fetchLider();

        if (currentUser) {
            setUsername(currentUser.username || "");
            setEmail(currentUser.email || "");
            setAcessoGDocs(currentUser.acesso_GDocs || false);
            setSelectedOptionsLideres(String(currentUser.iD_Lider_Time));
            setEhLiderTime(currentUser.eh_Lider_Time || false);
            setAtualizarADBaseGdocs(currentUser.atualizar_AD_Base_Gdocs || false);
            setAtivo(currentUser.ativo || false);
            setRamal(currentUser.ramal || "");
            setWhatsPessoal(currentUser.whats_Pessoal || "");
            setSelectedPermissions(currentUser.permissions || []);
            if (currentUser.urL_Foto) {
                setPhoto(currentUser.urL_Foto);
            }
        }
    }, [currentUser]);

    const handleSelectChangeLideres = (event: any, newValue: any) => {
        if (newValue) {
            setSelectedOptionsLideres(newValue.id);
        } else {
            setSelectedOptionsLideres(null);
        }
    };

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setSeverity("success");
        setMsgAlert("Usuário cadastrado com Sucesso!");

        if (password !== confirmaSenha) {
            setSeverity("error");
            setMsgAlert("As senhas digitadas não são iguais.");
            setLoading(false);
            setOpen(true);
            return;
        }

        if (!email.endsWith("@guarida.com.br")) {
            setSeverity("error");
            setMsgAlert("Por favor, digite um email válido da empresa.");
            setLoading(false);
            setOpen(true);
            return;
        }

        try {
            // Atualizar usuario
            if (currentUser !== undefined) {
                if (password.length > 0 && password.length < 8) {
                    setSeverity("error");
                    setMsgAlert("A senha deve ter no mínimo de 8 caracteres.");
                    setLoading(false);
                    setOpen(true);
                    return;
                }

                const updatedUser: ICadastroUsuario = {
                    id: currentUser.id,
                    username: username,
                    email: email,
                    password: password,
                    permissions: selectedPermissions,
                    acesso_GDocs: acessoGDocs,
                    eh_Lider_Time: ehLiderTime,
                    atualizar_AD_Base_Gdocs: atualizarADBaseGdocs,
                    iD_Lider_Time: Number(selectedOptionsLideres),
                    ativo: ativo,
                    ramal: ramal,
                    whats_Pessoal: whatsPessoal
                };

                var retorno = await cadastraUsuario(updatedUser);
                setMsgAlert("Usuário atualizado com sucesso!");
            } else {
                if (password.length >= 8) {
                    const usuario: ICadastroUsuario = {
                        username: username,
                        email: email,
                        password: password,
                        permissions: selectedPermissions,
                        acesso_GDocs: acessoGDocs,
                        eh_Lider_Time: ehLiderTime,
                        atualizar_AD_Base_Gdocs: atualizarADBaseGdocs,
                        iD_Lider_Time: Number(selectedOptionsLideres),
                        ativo: ativo,
                        ramal: ramal,
                        whats_Pessoal: whatsPessoal
                    };

                    var retorno = await cadastraUsuario(usuario);
                    console.log("retorno", { retorno });
                    setMsgAlert("Cadastro bem-sucedido!");
                }
                else {
                    setSeverity("error");
                    setMsgAlert("A senha deve ter no mínimo de 8 caracteres.");
                    setLoading(false);
                    setOpen(true);
                    return;
                }
            }

            limparComponentes();

            setLoading(false);
            setOpen(true);

            navigate('/ti/usuarios');

        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                setSeverity("error");
                setMsgAlert((error.response.data as any).mensagem);
                setLoading(false);
                setOpen(true);
                return;
            }

            console.error("Ocorreu um erro ao cadastrar o usuário: ", error);
            setSeverity("error");
            setMsgAlert("Ocorreu um erro ao cadastrar o usuário.");
            setLoading(false);
            setOpen(true);
        };
    }

    function limparComponentes() {
        setEmail("");
        setPassword("");
        setConfirmaSenha("");
        setUsername("");
        setAcessoGDocs(true);
        setSelectedOptionsLideres(null);
        setEhLiderTime(false);
        setAtualizarADBaseGdocs(false);
        setAtivo(true);
        setRamal("");
        setWhatsPessoal("");
        setPhoto("");
    }

    const handlePermissionChange = (e: any, id: any) => {
        if (e.target.checked) {
            setSelectedPermissions([...selectedPermissions, id]);
        } else {
            setSelectedPermissions(selectedPermissions.filter(selectedId => selectedId !== id));
        }
    };

    const formatPhoneNumber = (value: string) => {
        let cleaned = ('' + value).replace(/\D/g, '');
        if (cleaned.startsWith('0')) {
            cleaned = cleaned.substring(1);
        }
        // Formatação brasileira (XX) XXXXX-XXXX
        const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
        if (match) {
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        }

        return value; // Retorna o valor inserido se não corresponder ao esperado
    };

    const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const formattedPhoneNumber = formatPhoneNumber(e.target.value);
        setWhatsPessoal(formattedPhoneNumber);
    };

    const contents = loading
        ? <Loading /> : "";

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === "success" ? "success" : "error"} sx={{ width: '100%' }}>
                    {msgAlert}
                </Alert>
            </Snackbar>

            <h1 id="tableLabel">Cadastrar Usuário/Editar Permissões</h1>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '50ch' },
                }}
                noValidate
                autoComplete="off"
            >
                <div style={{ display: 'flex', justifyContent: 'left', marginBottom: '1rem' }}>
                    <Avatar
                        src={photo ? photo.toString() : ""}
                        alt="Foto do Usuário"
                        sx={{ width: 70, height: 70 }}
                    />
                </div>
                
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={ativo} onChange={(e) => setAtivo(e.target.checked)} />} label="Ativo" />
                    <FormControlLabel control={<Checkbox checked={acessoGDocs} onChange={(e) => setAcessoGDocs(e.target.checked)} />} label="Acesso ao GDocs" />
                    <FormControlLabel control={<Checkbox checked={ehLiderTime} onChange={(e) => setEhLiderTime(e.target.checked)} />} label="É líder de time?" />
                    <FormControlLabel control={<Checkbox checked={atualizarADBaseGdocs} onChange={(e) => setAtualizarADBaseGdocs(e.target.checked)} />} label="Atualizar AD Base Gdocs?" />
                </FormGroup>

                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                        required
                        label="Nome Usuario"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        required
                        label="Email"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <Autocomplete
                        disablePortal
                        id="combo-box-lider"
                        options={lideres}
                        value={lideres.find((option) => option.id === selectedOptionsLideres) || null}
                        onChange={handleSelectChangeLideres}
                        renderInput={(params) => <TextField {...params} label="Superior Imediato" variant="outlined" />}
                    />

                    <TextField
                        label="Ramal"
                        value={ramal}
                        onChange={(e) => setRamal(e.target.value)}
                    />

                    <TextField
                        label="WhatsApp Pessoal"
                        value={whatsPessoal}
                        onChange={handlePhoneInputChange}
                    />

                    {(currentUser === undefined) ? (
                        <div>
                            <div>
                                <TextField
                                    required
                                    label="Senha"
                                    type="password"
                                    autoComplete="new-password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>

                            <div>
                                <TextField
                                    required
                                    label="Confirmar Senha"
                                    type="password"
                                    value={confirmaSenha}
                                    onChange={(e) => setConfirmaSenha(e.target.value)}
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            <div>
                                <TextField
                                    label="Senha"
                                    type="password"
                                    autoComplete="new-password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </div>

                            <div>
                                <TextField
                                    label="Confirmar Senha"
                                    type="password"
                                    value={confirmaSenha}
                                    onChange={(e) => setConfirmaSenha(e.target.value)}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <div style={{ flexDirection: 'row' }}>
                    <div style={{ paddingLeft: '2rem', paddingBottom: '1rem' }}>
                        <h4>permissões:</h4>

                        {permissions.map(permission => (
                            <FormControlLabel

                                sx={{ display: 'flex' }}
                                key={permission.id}
                                control={
                                    <Checkbox
                                        checked={selectedPermissions.includes(permission.id)}
                                        onChange={(e) => handlePermissionChange(e, permission.id)}
                                    />
                                }
                                label={permission.descricao}
                            />
                        ))}
                    </div>
                </div>

                <Button
                    sx={{ marginLeft: "1%" }} variant="contained" endIcon={<SendIcon />} onClick={handleSubmit}
                >
                    Salvar
                </Button>
                <Button variant="outlined" color="secondary" style={{ marginLeft: 10 }} component={Link} to="/ti/usuarios">
              Cancelar
            </Button>
            </Box>

            {contents}

        </div>
    );
}

export default CadastroUsuarioPage;
