import React, { useEffect } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import FilterFormBuscaIntegracao from '../../pages/Seguros/BuscaIntegracaoBetalabs/FilterFormBuscaIntegracao';

interface IntegracaoBetalabsFormProps {
    handleSubmit: (event: React.FormEvent) => void;
    handleChangeItensPagina: (event: SelectChangeEvent<number>) => void;
    itensPagina: number;
    formData: any;
    setFormData: (data: any) => void;
    setTipoBusca: (tipoBusca: number) => void; 
}

const BuscaIntegracaoForm: React.FC<IntegracaoBetalabsFormProps> = ({
    handleSubmit,
    handleChangeItensPagina,
    itensPagina,
    formData,
    setFormData,
    setTipoBusca,
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSelectChange = (event: SelectChangeEvent<unknown>) => {
        const { name, value } = event.target as HTMLInputElement;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === 'TipoBusca') {
            setTipoBusca(Number(value));
        }
    };

    useEffect(() => {
        setTipoBusca(formData.TipoBusca);
    }, [formData.TipoBusca, setTipoBusca]);

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ display: "flex" }}>
                <FilterFormBuscaIntegracao
                    handleChange={handleChange}
                    handleSelectChange={handleSelectChange}
                    formData={formData}
                />
            </div>
            <Grid container sx={{ mt: 2 }} >
                <Grid item xs={2}>
                    <FormControl
                        sx={{
                            width: '100%',
                            marginTop: '10px',
                            marginRight: '10px'
                        }}>
                        <InputLabel className="customLabel">Registros por página</InputLabel>
                        <Select
                            value={itensPagina}
                            onChange={handleChangeItensPagina}
                            className="customSelect"
                            variant="filled"
                            inputProps={{
                                name: 'itensPagina',
                                id: 'itens-pagina',
                            }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={10}>
                    <Button type="submit" sx={{ margin: '10px', float: 'right', width: '20%', height: 50 }} variant="contained">
                        Buscar
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default BuscaIntegracaoForm;