import React from 'react';
import { FormControl, SelectChangeEvent, TextField, Select, InputLabel, MenuItem } from "@mui/material";

interface FilterFormIntegradorProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<unknown>) => void;
    formData: any;
}

const FilterFormBuscaIntegracao: React.FC<FilterFormIntegradorProps> = ({ handleChange, handleSelectChange, formData }) => {
    return (
        <>
            <FormControl
                sx={{
                    width: '15%',
                    minWidth: '15%',
                    marginTop: '8px',
                    marginRight: '8px',
                }}>
                <TextField
                    label="Código Condomínio"
                    name="Codigo_condominio"
                    value={formData.Codigo_condominio}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>

            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '15%',
                    marginTop: '8px',
                    marginRight: '8px',
                }}>
                <InputLabel id="tipo-busca-label">Tipo de busca</InputLabel>
                <Select
                    labelId="tipo-busca-label"
                    name="TipoBusca"
                    value={formData.TipoBusca || 0}
                    onChange={handleSelectChange}
                    label="Tipo de busca"
                    inputProps={{
                        id: 'TipoBusca-select',
                    }}
                >
                    <MenuItem value={0}>Integração de condomínios</MenuItem>
                    <MenuItem value={1}>Integração de pessoas por condomínio</MenuItem>
                </Select>
            </FormControl>
        </>
    );
};

export default FilterFormBuscaIntegracao;