import { Divider, Typography } from "@mui/material";
import "../../../styles/styles.css";
import Relatorio from "../../../components/common/Relatorio";
import { IBloqueioEmail } from "../interfaces/IBloqueioEmail";
import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/auth";

function BloqueioEmail() {
    const {buscaBloqueioEmail, exportaBloqueioEmail } = useContext(AuthContext);
    const [ficha, setFicha] = useState<IBloqueioEmail[]>([]);
    return (
        <>
            <Typography variant="h6">Bloqueio de Emails</Typography>
            <Relatorio
                busca={buscaBloqueioEmail}
                exporta={exportaBloqueioEmail}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"BloqueioEmail"}
            />
            <Divider variant="middle" />
        </>
    );
}

export default BloqueioEmail;
