import React from 'react';
import { Box, Typography } from "@mui/material";

const NotFound: React.FC = () => {
    return (
        <Box
            sx={{
                textAlign: 'center',
                marginTop: '100px',
                color: '#000',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                variant="h1"
                sx={{
                    fontSize: '60px',
                    fontWeight: 500,
                    paddingRight: '20px',
                    marginRight: '20px',
                    lineHeight: '49px',
                    borderRight: 'solid 2px #c2c2c2'
                }}
            >
                404
            </Typography>
            <div style={{ textAlign: 'left', lineHeight: '39px', height: '49px', verticalAlign: 'middle' }}>
                <Typography variant="h2" sx={{ fontSize: '20px', fontWeight: 'normal', margin: "10px 0", padding: 0 }}>
                    Página não encontrada.
                </Typography>
            </div>
        </Box>
    )
};

export default NotFound;
