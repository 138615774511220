import { Drawer, List, Stack, Toolbar, } from "@mui/material";
import assets from "../../assets";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import appRoutes from "../../routes/appRoutes";
import SidebarItem from "./SidebarItem";
import SidebarItemCollapse from "./SidebarItemCollapse";
import SidebarItemMb from "./SidebarItemMb";
import { Link as RouterLink } from 'react-router-dom';

interface SidebarProps {
  open: boolean;
  toggleDrawer: () => void;
}

const Sidebar: React.FC<SidebarProps> = ({ open, toggleDrawer }) => {
  return (
    <Drawer
      variant="permanent"
      sx={{
        width: sizeConfigs.sidebar.width,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: "80px",
          boxSizing: "border-box",
          borderRight: "0px",
          backgroundColor: colorConfigs.sidebar.bg,
          color: colorConfigs.sidebar.color,
          ...(open && {
            width: sizeConfigs.sidebar.width,
            transition: "width 0.5s ease-in-out"
          })
        }
      }}
    >
      {open ? (
        <List disablePadding>
          <Toolbar component={RouterLink} to="/" sx={{ marginBottom: "20px" }}>
            <Stack
              sx={{ width: "100%" }}
              direction="row"
              justifyContent="center"
            >
              <img src={assets.images.logo} alt='logo' />
            </Stack>
          </Toolbar>
          {appRoutes.map((route, index) => (
            route.sidebarProps ? (
              route.child ? (
                <SidebarItemCollapse item={route} key={index} />
              ) : (
                <SidebarItem item={route} key={index} />
              )
            ) : null
          ))}
        </List>
      ) : (
        <List disablePadding>
          <Toolbar component={RouterLink} to="/" sx={{ marginBottom: "20px" }}>
            <Stack
              sx={{ width: "100%", height: "50px", marginTop: "30px" }}
              direction="row"
              justifyContent="center"
            >
              <img src={assets.images.logo_g_branco} alt='logo' />
            </Stack>
          </Toolbar>
          {appRoutes.map((route, index) => (
            route.sidebarProps ? (
                <SidebarItemMb item={route} key={index} toggleDrawer={toggleDrawer} />
              ) : null
          ))}
        </List>
      )}
    </Drawer>
  );
};

export default Sidebar;