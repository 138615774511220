import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { IntegradorImoveis } from '../../../interfaces/ISite';
import Loading from '../../../components/common/Loading';
import { formatarData } from '../../../utils/formatUtils';
import Modal from '../../../components/common/Modal';
import '../../../components/common/Relatorio.css'


interface TableDataProps {
  ficha: IntegradorImoveis[];
  loading: boolean;
  msgAlert: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
}

const TableData: React.FC<TableDataProps> = ({ ficha, loading, msgAlert, modalOpen, handleCloseModal }) => {
  return (
    <div className="responsive-table-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Sucesso</TableCell>
            <TableCell>ID</TableCell>
            <TableCell>Código Imovel</TableCell>
            <TableCell>Integração</TableCell>
            <TableCell>Mensagem</TableCell>
            <TableCell>Stack</TableCell>
            <TableCell>Data Criação</TableCell>
            <TableCell>Negocio</TableCell>
          </TableRow>
        </TableHead>
        {loading ? <Loading /> : ''}
        <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
        <TableBody>
          {ficha.map((item) => (
            <TableRow key={item?.Id}>
              <Checkbox checked={item.Sucesso} />
              <TableCell>{item.Id}</TableCell>
              <TableCell>{item.Codigo}</TableCell>
              <TableCell>{item.Integracao}</TableCell>
              <TableCell>{item.Mensagem}</TableCell>
              <TableCell>{item.Stack}</TableCell>
              <TableCell>{formatarData(item.Criado_em)}</TableCell>
              <TableCell>{item.Negocio === 0 ? 'Alugar' : 'Vendas'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableData;
