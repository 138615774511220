import React, {Dispatch, SetStateAction, useContext} from 'react';
import '../../../components/common/modal.css';
import { Button } from '@mui/material';
import { AuthContext } from "../../../contexts/auth";
import { IBloqueioEmail } from '../interfaces/IBloqueioEmail';

interface RemoveEmailModalProps {
    ficha: IBloqueioEmail[];
    setFicha(ficha: any): void;
    isOpen: boolean;
    onClose: () => void;
    text: string;
    user: IBloqueioEmail | null;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setSeverity: Dispatch<SetStateAction<'success' | 'error'>>;
    setMsg: Dispatch<SetStateAction<string>>;
}

const RemoveEmailModalProps: React.FC<RemoveEmailModalProps> = ({ ficha, setFicha, isOpen, onClose, text, user, setMsg, setSeverity, setOpen  }) => {
    const { deletaBloqueioEmail } = useContext(AuthContext);
    if (!isOpen || !user) {
        return null;
    }

    const handleRemove = async () => {
        try {
            const response = await deletaBloqueioEmail(user.ClientEmail, user.EmailType);
            if (response == 200) {
                const novaFicha = ficha.filter((userAux: IBloqueioEmail)=>
                    !(userAux.ClientEmail === user.ClientEmail && userAux.EmailType === user.EmailType));
                setFicha(novaFicha);
                setMsg("Email removido com sucesso");
                setSeverity("success");
                setOpen(true);
            }
            onClose();
        } catch (error) {
            setMsg("Erro ao remover email");
            setSeverity("error");
            setOpen(true);
        }
    };

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-body">
                    <p>{text}</p>
                </div>
                <div className="modal-footer">
                    <Button onClick={handleRemove} sx={{ mr: 1 }}>Sim</Button>
                    <Button onClick={onClose}>Cancelar</Button>
                </div>
            </div>
        </div>
    );
};

export default RemoveEmailModalProps;