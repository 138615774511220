import { FormControl, FormHelperText, Grid, SelectChangeEvent, TextField } from "@mui/material";

interface IFilterFormAniversarioProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<HTMLSelectElement>) => void
    handleChangeDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
    formData: any;
}

const FilterFormAniversario: React.FC<IFilterFormAniversarioProps> = ({ handleChange, handleSelectChange, handleChangeDate, formData }) => {
    const defaultDataDe = formData.dataInicial ?
        (formData.dataInicial.toISOString().substring(0, 10) === '0001-01-01' ?
            "" : formData.dataInicial.toISOString().substring(0, 10)
        ) : "";
        
    const defaultDataAte = formData.dataFinal ?
        (formData.dataFinal.toISOString().substring(0, 10) === '0001-01-01' ?
            "" : formData.dataFinal.toISOString().substring(0, 10)
        ) : "";

    const campoObrigatorio =
        (formData.dataInicial && formData.dataFinal && formData.dataFinal.toISOString().substring(0, 10) !== '0001-01-01') ||
        (!formData.dataInicial && formData.dataFinal) ||
        (formData.dataInicial && formData.dataInicial.toISOString().substring(0, 10) !== '0001-01-01' && !formData.dataFinal);

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormControl
                        sx={{
                            width: '100%',
                            minWidth: '100%',
                            marginTop: '8px',
                            marginRight: '8px',
                        }}>
                        <FormHelperText sx={{ mb: 1 }}>Busca por período:</FormHelperText>

                        <TextField
                            sx={{ mb: 2 }}
                            label="De:"
                            type="date"
                            name="dataInicial"
                            value={defaultDataDe}
                            onChange={handleChangeDate}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required={campoObrigatorio}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl
                        sx={{
                            width: '100%',
                            minWidth: '100%',
                            marginTop: '8px',
                            marginLeft: '8px',
                        }}>
                        <FormHelperText sx={{ mb: 1 }}>Busca por período:</FormHelperText>

                        <TextField
                            sx={{ mb: 2 }}
                            label="Até:"
                            type="date"
                            name="dataFinal"
                            value={defaultDataAte}
                            onChange={handleChangeDate}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required={campoObrigatorio}
                        />
                    </FormControl>
                </Grid>
            </Grid>

        </>
    );
};


export default FilterFormAniversario;