import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState, useContext, } from "react";
import Relatorio from "../../../components/common/Relatorio";
import { AuthContext } from "../../../contexts/auth";
import { IRelatorioEmailAniversario } from "../interfaces/IAniversarios";
import "../../../styles/styles.css";

function RelatorioEmailsAniversario() {
const {buscaRelatorioEmailAniversario, exportaRelatorioEmailAniversario } = useContext(AuthContext);
const [ficha, setFicha] = useState<IRelatorioEmailAniversario[]>([]);

    return (
        <>
            <Typography variant="h6">Relatório de Emails de Aniversário</Typography>
            <Relatorio
                busca={buscaRelatorioEmailAniversario}
                exporta={exportaRelatorioEmailAniversario}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"EmailAniversario"}
            />
            <Divider variant="middle" />
        </>
    );
}

export default RelatorioEmailsAniversario;
