import React, { useState, useContext } from 'react';
import { Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import Loading from '../../components/common/Loading';
import { AuthContext } from "../../contexts/auth";
import SendIcon from '@mui/icons-material/Send';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Box from '@mui/material/Box';

type Props = {};

const EnviarSMSLotePage = (props: Props) => {

    const [telefones, setTelefones] = useState("");
    const [mensagem, setMensagem] = useState("");
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [msgAlert, setMsgAlert] = useState("");
    const { envioSMS } = useContext(AuthContext);

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setSeverity("success");
        setMsgAlert("Requisicao executada com Sucesso!");

        console.log("submit", { telefones });
        try {
            let retorno = await envioSMS(mensagem, telefones);
            console.log("retorno", { retorno });  
        } catch (err: any) {
            console.log(err.response.data);
            setSeverity("error");
            setMsgAlert(err.response.data['mensagem']);
        }
              
        setLoading(false);
        setOpen(true);
        setTelefones("");
    };

    const contents = loading
        ? <Loading /> : "";

    return (
        <div>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === "success" ? "success" : "error"} sx={{ width: '100%' }}>
                    {msgAlert}
                </Alert>
            </Snackbar>
            <h1 id="tableLabel"> Envio de SMS em Lote</h1>
            <p>Telefones (informe separados por ,) </p>

            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: '45ch' },
                }}
                noValidate
                autoComplete="off"
            >
            <TextField
                size="small"
                required
                id="outlined-required"
                label="Telefones"
                    value={telefones} onChange={(e) => setTelefones(e.target.value)}
            />
            <div>
                <TextField
                    id="outlined-multiline-static"
                    label="Mensagem SMS"
                    multiline
                    rows={6}
                        value={mensagem} onChange={(e) => setMensagem(e.target.value)}
                />
            </div>

            <Button sx={{ "margin-left": "1%" }} variant="contained" endIcon={<SendIcon />} onClick={handleSubmit}>
                    Enviar
                </Button>
            </Box>

            {contents}

        </div>
    );
};

export default EnviarSMSLotePage;