import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { FilterFormDesocupacaoProps } from "./IDesocupacao";

const FilterFormDesocupacao: React.FC<FilterFormDesocupacaoProps> = ({ handleChange, handleSelectChange, handleChangeDate, formData }) => {
    const defaultDataDe = formData.Data_Criacao ?
        (formData.Data_Criacao.toISOString().substring(0, 10) === '0001-01-01' ?
            "" : formData.Data_Criacao.toISOString().substring(0, 10)
        ) : "";
    return (
        <>
            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '20%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="Código Imóvel"
                    name="CodImovel"
                    value={formData.CodImovel === "0" ? "" : formData.CodImovel}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>

            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '20%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="Código Imoview"
                    name="Codigo_Imoview"
                    value={formData.Codigo_Imoview === "0" ? "" : formData.Codigo_Imoview}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>

            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '20%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <InputLabel htmlFor="outlined-select">Atualizado Imoview</InputLabel>
                <Select
                    variant="filled"
                    name="Processado_Imoview"
                    value={formData["Processado_Imoview"]}
                    onChange={handleSelectChange}
                    inputProps={{
                        name: 'Processado_Imoview',
                        id: 'Processado_Imoview_select',
                    }}
                >
                    <MenuItem value={(true as any)}>Sim</MenuItem>
                    <MenuItem value={(false as any)}>Não</MenuItem>
                </Select>
            </FormControl>
            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '20%',
                    marginTop: '8px',
                    marginRight: '8px',
                }}>

                <TextField
                    sx={{ mb: 2 }}
                    label="Criado Em:"
                    type="date"
                    name="Data_Criacao"
                    value={defaultDataDe}
                    onChange={handleChangeDate}
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </FormControl>

        </>
    );
};


export default FilterFormDesocupacao;