import { useContext, useEffect, useState } from "react";
import { ICondominio } from "../../interfaces/ICondominio";
import { AuthContext } from "../../contexts/auth";
import { Button, Checkbox, MenuItem, Select, TextField } from "@mui/material";
import { SelectChangeEvent } from '@mui/material/Select';
import Loading from "../../components/common/Loading";

function BloqueioInadimplentes() {
  const [condominios, setCondominios] = useState<ICondominio[]>([]);
  const { pegaCondominios, postBloqueioInadimplentes } = useContext(AuthContext);
  const [filteredCondominios, setFilteredCondominios] = useState<ICondominio[]>([]);
  const [filterText, setFilterText] = useState<string>('');
  const [selectedCondominio, setSelectedCondominio] = useState<ICondominio | null>();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCondominios = async () => {
      try {
        const condominiosData = await pegaCondominios();
        setCondominios(condominiosData);
        setFilteredCondominios(condominiosData);
        if (condominiosData.length > 0) {
          setSelectedCondominio(condominiosData[0].Id);
        }
      } catch (error) {
        console.error('Error fetching condominios:', error);
      }
    };

    setEditMode(false);
    setFilterText("");
    fetchCondominios();
  }, [pegaCondominios]);

  useEffect(() => {
    if (filteredCondominios.length > 0) {
      setSelectedCondominio(filteredCondominios[0]);
    }
    console.log(selectedCondominio);

  }, [filteredCondominios]);

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const filtered = condominios.filter(condominio =>
      condominio.Nome.toLowerCase().includes(value.toLowerCase()) ||
      condominio.Codcondom.toString().toLowerCase().includes(value.toLowerCase())
    );
    setEditMode(false);
    setFilterText(value);
    setFilteredCondominios(filtered);
  };

  const handleDropdownChange = (event: SelectChangeEvent<number | string>) => {
    const cond = filteredCondominios.find(x => x.Id === event.target.value);
    console.log('cond: ', cond);

    setSelectedCondominio(cond);
  };

  const handleSaveClick = async () => {
    setLoading(true);
    const result = await postBloqueioInadimplentes({ id_condominio: selectedCondominio?.Id, bloqueado: selectedCondominio?.Bloqueado });

    if (result.status === 200 && selectedCondominio) {
      setCondominios(prevCondominios =>
        prevCondominios.map(condominio => {
          if (condominio.Id === selectedCondominio.Id) {
            return { ...condominio, Bloqueado: selectedCondominio.Bloqueado };
          }
          return condominio;
        }))
    }
    setEditMode(false);
    setLoading(false);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedCondominio) setSelectedCondominio({ ...selectedCondominio, Bloqueado: event.target.checked });
  };

  const handleCnpj = (cnpj: string) => cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');

  return (
    <div>
      <h1>Condomínios</h1>
      <TextField
        type="text"
        placeholder="Search..."
        value={filterText}
        onChange={handleFilterChange}
      />
      <Select value={selectedCondominio?.Id || ''} onChange={handleDropdownChange}>
        <MenuItem value="">Select Condomínio</MenuItem>
        {filteredCondominios.map(condominio => (
          <MenuItem key={condominio.Id} value={condominio.Id}>
            {condominio.Nome}
          </MenuItem>
        ))}
      </Select>
      {selectedCondominio !== null && (
        <div style={{ paddingLeft: '8px' }}>
          <h2>Condominio {selectedCondominio?.Nome}</h2>
          <p>Cnpj: {selectedCondominio?.Cnpj && handleCnpj(selectedCondominio?.Cnpj)}</p>
          <p>Codigo Condominio: {selectedCondominio?.Codcondom}</p>
          <p>Bloqueado: {selectedCondominio?.Bloqueado ? (
            <Checkbox
              checked={true}
              onChange={handleCheckboxChange}
              disabled={!editMode}
            />
          ) : (
            <Checkbox
              checked={false}
              onChange={handleCheckboxChange}
              disabled={!editMode}
            />
          )}</p>
          {editMode ? (
            <div>
              <Button variant="contained" size="small" onClick={() => handleSaveClick()} style={{ marginRight: "10px" }}> Salvar </Button>
              <Button variant="contained" size="small" onClick={() => setEditMode(false)}> Cancelar </Button>
            </div>
          ) : (
            <Button variant="contained" size="small" onClick={() => setEditMode(true)}> Editar </Button>
          )}
          {loading && <Loading />}
        </div>
      )}
    </div>
  );
}

export default BloqueioInadimplentes;