import React from 'react';
import './modal.css';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    text: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, text }) => {
    if (!isOpen) {
        return null;
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-body">
                    <p>{text}</p>
                </div>
                <div className="modal-footer">
                    <button onClick={onClose}>OK</button>
                </div>
            </div>
        </div>
    );
};

export default Modal;