import React, { useRef, useState, useContext, useEffect } from "react";
import { Box, Button, Checkbox, Divider, FormControlLabel, FormControl, FormHelperText, MenuItem, Select, Snackbar, Step, Stepper, StepContent, StepLabel, TextField, Autocomplete } from "@mui/material";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Loading from "../../components/common/Loading";
import { AuthContext } from "../../contexts/auth";
interface Bairro {
    bairroId: number;
    bairroNomes: string;
}

interface Tipo {
    tipoId: number;
    tipoDescricao: string;
}

function CalculadoraAlugueisPage() {
    const [activeStep, setActiveStep] = useState(0);
    const [bairro, setBairro] = useState<Bairro | null>(null);
    const [bairros, setBairros] = useState<Bairro[]>([]);
    const [tipo, setTipo] = useState<Tipo | null>(null);
    const [tipos, setTipos] = useState<{ tipoDescricao: string; tipoId: number }[]>([]);
    const [andar, setAndar] = useState(0);
    const [areaTotal, setAreaTotal] = useState("0");
    const [qtdBanheiros, setQtdBanheiros] = useState(0);
    const [qtdElevadores, setQtdElevadores] = useState(0);
    const [qtdQuartos, setQtdQuartos] = useState(0);
    const [qtdSalas, setQtdSalas] = useState(0);
    const [qtdSuites, setQtdSuites] = useState(0);
    const [qtdVagas, setQtdVagas] = useState(0);

    const [valorCondominio, setValorCondominio] = useState("R$ 0,00");
    const [valorIptu, setValorIptu] = useState("R$ 0,00");

    const { pegaBairros } = useContext(AuthContext);
    const { pegaTipos } = useContext(AuthContext);
    const { calculaAluguel } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState("success");
    const [resultadoCalculo, setResultadoCalculo] = useState<string | null>(null);
    const [tipoError, setTipoError] = useState(true);
    const [bairroError, setBairroError] = useState(true)

    const [churrasqueira_imo, setChurrasqueira_imo] = useState(false);
    const [sacada, setSacada] = useState(false);
    const [aquecimentoGas, setAquecimentoGas] = useState(false);
    const [arCondicionado, setArCondicionado] = useState(false);
    const [areaServico, setAreaServico] = useState(false);
    const [armarioBanheiro, setArmarioBanheiro] = useState(false);
    const [armarioCozinha, setArmarioCozinha] = useState(false);
    const [armarioQuarto, setArmarioQuarto] = useState(false);
    const [boxBanheiro, setBoxBanheiro] = useState(false);
    const [closet, setCloset] = useState(false);
    const [despensa, setDespensa] = useState(false);

    const [hidromassagem, setHidromassagem] = useState(false);
    const [lavabo, setLavabo] = useState(false);
    const [mobiliado, setMobiliado] = useState(false);
    const [solManha, setSolManha] = useState(false);

    const [aguaIndividual, setAguaIndividual] = useState(false);

    const [gasCanalizado, setGasCanalizado] = useState(false);

    const [portaria, setPortaria] = useState(false);
    const [academia, setAcademia] = useState(false);
    const [churrasqueira_cond, setChurrasqueira_cond] = useState(false);
    const [piscina, setPiscina] = useState(false);
    const [playground, setPlayground] = useState(false);
    const [salaoFestas, setSalaoFestas] = useState(false);
    const [salaoJogos, setSalaoJogos] = useState(false);

    useEffect(() => {
        const buscaBairros = async () => {
            try {
                const response = await pegaBairros();
                setBairros(response);
            } catch (error) {
                console.error("Erro ao buscar bairros", error);
            }
        };

        buscaBairros();
    }, [pegaBairros]);

    useEffect(() => {
        const buscaTipos = async () => {
            try {
                const response = await pegaTipos();
                setTipos(response);
            } catch (error) {
                console.error("Erro ao buscar os tipos", error);
            }
        };

        buscaTipos();
    }, [pegaTipos]);

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setSeverity("success");
        setMsgAlert("Cálculo realizado com sucesso!");

        if (tipo === null || bairro === null) {
            setSeverity("error");
            setMsgAlert("Os campos Tipo e Bairro são obrigatórios.");
            setLoading(false);
            setOpen(true);
            return;
        }
        else {
            try {
                var auxValorFormatado = valorCondominio.split("R$");
                var valorCondominioAux = auxValorFormatado[1].trim().replace(",", ".");
                var valorCondominioConvertido = parseFloat(valorCondominioAux);

                var auxValorFormatado = valorIptu.split("R$");
                var valorIptuAux = auxValorFormatado[1].trim().replace(",", ".");
                var valorIptuConvertido = parseFloat(valorIptuAux);

                let retorno = await calculaAluguel(
                    tipo?.tipoDescricao,
                    bairro?.bairroNomes,
                    andar,
                    areaTotal,
                    qtdBanheiros,
                    qtdElevadores,
                    qtdQuartos,
                    qtdSalas,
                    qtdSuites,
                    qtdVagas,
                    valorCondominioConvertido,
                    valorIptuConvertido,
                    churrasqueira_imo,
                    sacada,
                    aquecimentoGas,
                    arCondicionado,
                    areaServico,
                    armarioBanheiro,
                    armarioCozinha,
                    armarioQuarto,
                    boxBanheiro,
                    closet,
                    despensa,
                    hidromassagem,
                    lavabo,
                    mobiliado,
                    solManha,
                    aguaIndividual,
                    gasCanalizado,
                    portaria,
                    academia,
                    churrasqueira_cond,
                    piscina,
                    playground,
                    salaoFestas,
                    salaoJogos,
                );

                let valorAjustado: number;
                const valorCalculado = retorno.aluguel;

                const dezena = valorCalculado % 100;

                if (dezena >= 0 && dezena <= 50) {
                    // Regra 1: Se a dezena estiver entre X00 e X50
                    valorAjustado = Math.floor(valorCalculado / 100) * 100 - 10;
                } else {
                    // Regra 2: Se a dezena estiver entre X51 e X00
                    valorAjustado = Math.floor(valorCalculado / 100) * 100 + 90;
                }

                // Formatando o valor ajustado para exibição
                const valorFormatado = valorAjustado.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                });

                setResultadoCalculo(valorFormatado);

                setMsgAlert("Cálculo bem-sucedido!");
                setLoading(false);
                setOpen(true);

            } catch (error: any) {
                if (error.response && error.response.status === 400) {
                    setSeverity("error");
                    setMsgAlert((error.response.data as any).mensagem);
                    setLoading(false);
                    setOpen(true);
                    return;
                }
                console.error("Ocorreu um erro ao calcular", error);
                setSeverity("error");
                setMsgAlert("Ocorreu um erro ao calcular.");
                setLoading(false);
                setOpen(true);
            }
        }
    };

    const resultadoCalculoSectionRef = useRef<HTMLDivElement>(null);

    const scrollIntoView = () => {
        if (resultadoCalculoSectionRef.current) {
            resultadoCalculoSectionRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    };

    useEffect(() => {
        scrollIntoView();
    }, [resultadoCalculo]);

    const formatarInput = (valor: string) => {
        const valorNumerico = parseFloat(valor.replace(/[^\d]/g, ''));
        if (isNaN(valorNumerico)) {
            return '';
        }

        const reais = Math.floor(valorNumerico / 100);
        const centavos = valorNumerico % 100;

        const valorFormatado = `${reais.toLocaleString('pt-BR')},${centavos
            .toString()
            .padStart(2, '0')}`;

        return `R$ ${valorFormatado}`;
    };

    const handleChange = (e: any, setValor: (valor: string) => void) => {
        const novoValor = e.target.value;

        const novoValorLimpo = novoValor.replace(/[^\d]/g, '');
        if (novoValorLimpo.length >= 1) {
            const valorFormatado = formatarInput(novoValorLimpo);

            setValor(valorFormatado);
        } else {
            setValor('');
        }
    };

    const resetFiltros = () => {
        setTipo(null);
        setTipoError(true);
        setBairro(null);
        setBairroError(true);
        setAndar(0);
        setAreaTotal("0");
        setQtdBanheiros(0);
        setQtdElevadores(0);
        setQtdQuartos(0);
        setQtdSalas(0);
        setQtdSuites(0);
        setQtdVagas(0);
        setValorCondominio("R$ 0,00");
        setValorIptu("R$ 0,00");
        setChurrasqueira_imo(false);
        setSacada(false);
        setAquecimentoGas(false);
        setArCondicionado(false);
        setAreaServico(false);
        setArmarioBanheiro(false);
        setArmarioCozinha(false);
        setArmarioQuarto(false);
        setBoxBanheiro(false);
        setCloset(false);
        setDespensa(false);
        setHidromassagem(false);
        setLavabo(false);
        setMobiliado(false);
        setSolManha(false);
        setAguaIndividual(false);
        setGasCanalizado(false);
        setPortaria(false);
        setAcademia(false);
        setChurrasqueira_cond(false);
        setPiscina(false);
        setPlayground(false);
        setSalaoFestas(false);
        setSalaoJogos(false);
        setResultadoCalculo(null);
    };

    const contents = loading ? <Loading /> : "";

    const handleChurrasqueiraImovelChange = (value: boolean) => {
        setChurrasqueira_imo(value);
    };

    const handleSacadaChange = (value: boolean) => {
        setSacada(value);
    };

    const handleAquecimentoGasChange = (value: boolean) => {
        setAquecimentoGas(value);
    };

    const handleArCondicionadoChange = (value: boolean) => {
        setArCondicionado(value);
    };

    const handleAreaServicoChange = (value: boolean) => {
        setAreaServico(value);
    };

    const handleArmarioBanheiroChange = (value: boolean) => {
        setArmarioBanheiro(value);
    };

    const handleArmarioCozinhaChange = (value: boolean) => {
        setArmarioCozinha(value);
    };

    const handleArmarioQuartoChange = (value: boolean) => {
        setArmarioQuarto(value);
    };

    const handleBoxBanheiroChange = (value: boolean) => {
        setBoxBanheiro(value);
    };

    const handleClosetChange = (value: boolean) => {
        setCloset(value);
    };

    const handleDespensaChange = (value: boolean) => {
        setDespensa(value);
    };

    const handleHidromassagemChange = (value: boolean) => {
        setHidromassagem(value);
    };

    const handleLavaboChange = (value: boolean) => {
        setLavabo(value);
    };

    const handleMobiliadoChange = (value: boolean) => {
        setMobiliado(value);
    };

    const handleSolManhaChange = (value: boolean) => {
        setSolManha(value);
    };

    const handleAguaIndividualChange = (value: boolean) => {
        setAguaIndividual(value);
    };

    const handleGasCanalizadoChange = (value: boolean) => {
        setGasCanalizado(value);
    };

    const handlePortariaChange = (value: boolean) => {
        setPortaria(value);
    };

    const handleAcademiaChange = (value: boolean) => {
        setAcademia(value);
    };

    const handleChurrasqueiraChange = (value: boolean) => {
        setChurrasqueira_cond(value);
    };

    const handlePiscinaChange = (value: boolean) => {
        setPiscina(value);
    };

    const handlePlaygroundChange = (value: boolean) => {
        setPlayground(value);
    };

    const handleSalaoFestasChange = (value: boolean) => {
        setSalaoFestas(value);
    };

    const handleSalaoJogosChange = (value: boolean) => {
        setSalaoJogos(value);
    };

    const getStepLabel = (index: number) => {
        switch (index) {
            case 0:
                return "Características do Imóvel - Informações";
            case 1:
                return "Características do Imóvel - Opções";
            case 2:
                return "Características do Condomínio";
            default:
                return "";
        }
    };

    const getStepContent = (step: number) => {
        switch (step) {
            case 0:
                return (
                    <Box>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "8px" }}>
                            <FormControl
                                sx={{
                                    width: "100%",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                                required
                                error={tipoError}
                            >
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <h4>Tipo:</h4>

                                    {tipoError && <FormHelperText style={{ color: "red", margin: 0 }}>*</FormHelperText>}
                                </div>
                                <Select
                                    variant="filled"
                                    name="tipo"
                                    value={tipo ? tipo.tipoId.toString() : ""}
                                    onChange={(e) => {
                                        const selectedTipoId = Number(e.target.value);
                                        const selectedTipo = tipos.find((t) => t.tipoId === selectedTipoId);
                                        setTipo(selectedTipo || null);
                                        if (selectedTipo != null) {
                                            setTipoError(false)
                                        }
                                    }}
                                >
                                    {tipos &&
                                        tipos.map((tipo) => (
                                            <MenuItem key={tipo.tipoId} value={tipo.tipoId}>
                                                {tipo.tipoDescricao}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>

                            <FormControl
                                sx={{
                                    width: "100%",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    paddingLeft: "20px",
                                }}
                                required
                                error={bairroError}
                            >
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <h4>Bairro:</h4>

                                    {bairroError && <FormHelperText style={{ color: "red", margin: 0 }}>*</FormHelperText>}
                                </div>
                                <Select
                                    variant="filled"
                                    name="bairro"
                                    value={bairro ? bairro.bairroId.toString() : ""}
                                    onChange={(e) => {
                                        const selectedBairroId = Number(e.target.value);
                                        const selectedBairro = bairros.find((bairro) => bairro.bairroId === selectedBairroId);
                                        setBairro(selectedBairro || null);
                                        if (selectedBairro != null) {
                                            setBairroError(false);
                                        }
                                    }}
                                >
                                    {bairros &&
                                        bairros.map((bairro) => (
                                            <MenuItem key={bairro.bairroId} value={bairro.bairroId}>
                                                {bairro.bairroNomes}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "8px", }}>
                            <FormControl
                                sx={{
                                    width: "100%",
                                    marginTop: "8px",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h4>Andar:</h4>

                                <Select
                                    variant="filled"
                                    type="number"
                                    value={andar}
                                    onChange={(e) => setAndar(e.target.value as number)}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={11}>11</MenuItem>
                                    <MenuItem value={12}>12</MenuItem>
                                    <MenuItem value={13}>13</MenuItem>
                                    <MenuItem value={14}>14</MenuItem>
                                    <MenuItem value={15}>15</MenuItem>
                                    <MenuItem value={16}>16</MenuItem>
                                    <MenuItem value={17}>17</MenuItem>
                                    <MenuItem value={18}>18</MenuItem>
                                    <MenuItem value={19}>19</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl
                                sx={{
                                    width: "100%",
                                    marginTop: "8px",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    paddingLeft: "20px"
                                }}
                            >
                                <h4>Área interna em m²:</h4>

                                <TextField
                                    label=""
                                    variant="filled"
                                    type="number"
                                    value={areaTotal}
                                    onChange={(e) => setAreaTotal(e.target.value)}
                                />
                            </FormControl>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "8px", }}>
                            <FormControl
                                sx={{
                                    width: "100%",
                                    marginTop: "8px",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h4>Número de banheiros:</h4>

                                <Select
                                    variant="filled"
                                    type="number"
                                    value={qtdBanheiros}
                                    onChange={(e) => setQtdBanheiros(e.target.value as number)}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl
                                sx={{
                                    width: "100%",
                                    marginTop: "8px",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    paddingLeft: "20px"
                                }}
                            >
                                <h4>Número de elevadores:</h4>

                                <Select
                                    variant="filled"
                                    type="number"
                                    value={qtdElevadores}
                                    onChange={(e) => setQtdElevadores(e.target.value as number)}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "8px", }}>
                            <FormControl
                                sx={{
                                    width: "100%",
                                    marginTop: "8px",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h4 style={{ paddingRight: "10px" }}>Número de quartos:</h4>

                                <Select
                                    variant="filled"
                                    name="qtdquartos"
                                    value={qtdQuartos}
                                    type="number"
                                    onChange={(e) => setQtdQuartos(e.target.value as number)}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl
                                sx={{
                                    width: "100%",
                                    marginTop: "8px",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    paddingLeft: "20px"
                                }}
                            >
                                <h4>Número de salas:</h4>

                                <Select
                                    variant="filled"
                                    type="number"
                                    value={qtdSalas}
                                    onChange={(e) => setQtdSalas(e.target.value as number)}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "8px", }}>
                            <FormControl
                                sx={{
                                    width: "100%",
                                    marginTop: "8px",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h4>Número de suítes:</h4>

                                <Select
                                    variant="filled"
                                    type="number"
                                    value={qtdSuites}
                                    onChange={(e) => setQtdSuites(e.target.value as number)}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl
                                sx={{
                                    width: "100%",
                                    marginTop: "8px",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    paddingLeft: "20px"
                                }}
                            >
                                <h4>Número de vagas:</h4>

                                <Select
                                    variant="filled"
                                    type="number"
                                    value={qtdVagas}
                                    onChange={(e) => setQtdVagas(e.target.value as number)}
                                >
                                    <MenuItem value={0}>0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                    <MenuItem value={5}>5</MenuItem>
                                    <MenuItem value={6}>6</MenuItem>
                                    <MenuItem value={7}>7</MenuItem>
                                    <MenuItem value={8}>8</MenuItem>
                                    <MenuItem value={9}>9</MenuItem>
                                    <MenuItem value={10}>10</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", marginTop: "8px", }}>
                            <FormControl
                                sx={{
                                    width: "100%",
                                    marginTop: "8px",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <h4>Valor do IPTU (mensal):</h4>
                                <TextField
                                    variant="filled"
                                    type="text"
                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                    value={valorIptu}
                                    onChange={(e) => handleChange(e, setValorIptu)}
                                />
                            </FormControl>

                            <FormControl
                                sx={{
                                    width: "100%",
                                    marginTop: "8px",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    paddingLeft: "20px"
                                }}
                            >
                                <h4>Valor do condomínio:</h4>
                                <TextField
                                    variant="filled"
                                    type="text"
                                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                                    value={valorCondominio}
                                    onChange={(e) => handleChange(e, setValorCondominio)}
                                />
                            </FormControl>
                        </div>
                    </Box>
                );
            case 1:
                return (
                    <div style={{ display: 'flex', flexDirection: 'column', width: "100%" }}>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4>Churrasqueira no imóvel:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={churrasqueira_imo === true}
                                            onChange={() => handleChurrasqueiraImovelChange(true)}
                                            name="churrasqueira_imoSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={churrasqueira_imo === false}
                                            onChange={() => handleChurrasqueiraImovelChange(false)}
                                            name="churrasqueira_imoNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4>Sacada:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sacada === true}
                                            onChange={() => handleSacadaChange(true)}
                                            name="SacadaSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={sacada === false}
                                            onChange={() => handleSacadaChange(false)}
                                            name="sacadaNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4>Aquecedor a gás:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={aquecimentoGas === true}
                                            onChange={() => handleAquecimentoGasChange(true)}
                                            name="aquecimentoGasSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={aquecimentoGas === false}
                                            onChange={() => handleAquecimentoGasChange(false)}
                                            name="aquecimentoGasNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Ar condicionado:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={arCondicionado === true}
                                            onChange={() => handleArCondicionadoChange(true)}
                                            name="arCondicionadoSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={arCondicionado === false}
                                            onChange={() => handleArCondicionadoChange(false)}
                                            name="arCondicionadoNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Área de serviço fechada:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={areaServico === true}
                                            onChange={() => handleAreaServicoChange(true)}
                                            name="areaServicoSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={areaServico === false}
                                            onChange={() => handleAreaServicoChange(false)}
                                            name="areaServicoNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Armário no banheiro:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={armarioBanheiro === true}
                                            onChange={() => handleArmarioBanheiroChange(true)}
                                            name="armarioBanheiroSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={armarioBanheiro === false}
                                            onChange={() => handleArmarioBanheiroChange(false)}
                                            name="armarioBanheiroNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Armário na cozinha:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={armarioCozinha === true}
                                            onChange={() => handleArmarioCozinhaChange(true)}
                                            name="armarioCozinhaSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={armarioCozinha === false}
                                            onChange={() => handleArmarioCozinhaChange(false)}
                                            name="armarioCozinhaNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Armário no quarto:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={armarioQuarto === true}
                                            onChange={() => handleArmarioQuartoChange(true)}
                                            name="armarioQuartoSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={armarioQuarto === false}
                                            onChange={() => handleArmarioQuartoChange(false)}
                                            name="armarioQuartoNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Box no banheiro:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={boxBanheiro === true}
                                            onChange={() => handleBoxBanheiroChange(true)}
                                            name="boxBanheiroSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={boxBanheiro === false}
                                            onChange={() => handleBoxBanheiroChange(false)}
                                            name="boxBanheiroNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Closet:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={closet === true}
                                            onChange={() => handleClosetChange(true)}
                                            name="closetSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={closet === false}
                                            onChange={() => handleClosetChange(false)}
                                            name="closetNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Despensa:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={despensa === true}
                                            onChange={() => handleDespensaChange(true)}
                                            name="despensaSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={despensa === false}
                                            onChange={() => handleDespensaChange(false)}
                                            name="despensaNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Hidromassagem:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={hidromassagem === true}
                                            onChange={() => handleHidromassagemChange(true)}
                                            name="hidromassagemSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={hidromassagem === false}
                                            onChange={() => handleHidromassagemChange(false)}
                                            name="hidromassagemNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Lavabo:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={lavabo === true}
                                            onChange={() => handleLavaboChange(true)}
                                            name="lavaboSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={lavabo === false}
                                            onChange={() => handleLavaboChange(false)}
                                            name="lavaboNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Mobiliado:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={mobiliado === true}
                                            onChange={() => handleMobiliadoChange(true)}
                                            name="mobiliadoSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={mobiliado === false}
                                            onChange={() => handleMobiliadoChange(false)}
                                            name="MobiliadoNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Sol da manhã:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={solManha === true}
                                            onChange={() => handleSolManhaChange(true)}
                                            name="solManhaSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={solManha === false}
                                            onChange={() => handleSolManhaChange(false)}
                                            name="solManhaNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Água individual:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={aguaIndividual === true}
                                            onChange={() => handleAguaIndividualChange(true)}
                                            name="aguaIndividualSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={aguaIndividual === false}
                                            onChange={() => handleAguaIndividualChange(false)}
                                            name="aguaIndividualNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Gás central:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={gasCanalizado === true}
                                            onChange={() => handleGasCanalizadoChange(true)}
                                            name="gasCanalizadoSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={gasCanalizado === false}
                                            onChange={() => handleGasCanalizadoChange(false)}
                                            name="gasCanalizadoNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Portaria 24h:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={portaria === true}
                                            onChange={() => handlePortariaChange(true)}
                                            name="portariaSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={portaria === false}
                                            onChange={() => handlePortariaChange(false)}
                                            name="portariaNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Academia:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={academia === true}
                                            onChange={() => handleAcademiaChange(true)}
                                            name="academiaSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={academia === false}
                                            onChange={() => handleAcademiaChange(false)}
                                            name="academiaNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Churrasqueira no condomínio:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={churrasqueira_cond === true}
                                            onChange={() => handleChurrasqueiraChange(true)}
                                            name="churrasqueiraSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={churrasqueira_cond === false}
                                            onChange={() => handleChurrasqueiraChange(false)}
                                            name="churrasqueiraNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Piscina:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={piscina === true}
                                            onChange={() => handlePiscinaChange(true)}
                                            name="piscinaSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={piscina === false}
                                            onChange={() => handlePiscinaChange(false)}
                                            name="piscinaNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Playground:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={playground === true}
                                            onChange={() => handlePlaygroundChange(true)}
                                            name="playgroundSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={playground === false}
                                            onChange={() => handlePlaygroundChange(false)}
                                            name="playgroundNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Salão de festas:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={salaoFestas === true}
                                            onChange={() => handleSalaoFestasChange(true)}
                                            name="salaoFestasSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={salaoFestas === false}
                                            onChange={() => handleSalaoFestasChange(false)}
                                            name="salaoFestasNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', width: "85%", justifyContent: "space-between" }}>
                            <h4 style={{ paddingRight: '10px' }}>Salão de jogos:</h4>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={salaoJogos === true}
                                            onChange={() => handleSalaoJogosChange(true)}
                                            name="salaoJogosSim"
                                        />
                                    }
                                    label="Sim"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={salaoJogos === false}
                                            onChange={() => handleSalaoJogosChange(false)}
                                            name="salaoJogosNao"
                                        />
                                    }
                                    label="Não"
                                />
                            </div>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === "success" ? "success" : "error"} sx={{ width: "100%" }}>
                    {msgAlert}
                </Alert>
            </Snackbar>

            <h1 id="tableLabel" style={{ fontSize: "1rem", fontWeight: "500" }}>
                Calculadora Aluguéis
            </h1>

            <Divider variant="middle" />

            <Box
                component="form"
                sx={{
                    "& .MuiTextField-root, & .MuiInputBase-root": { my: 1, width: "20ch", height: "6ch" },
                    "& h4": {
                        fontSize: "14px",
                        fontWeight: "bold",
                    },
                    maxWidth: "50%",
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                }}
                noValidate
                autoComplete="off"
            >
                <Stepper activeStep={activeStep} orientation="vertical">
                    {[0, 1, 2].map((index) => (
                        <Step key={index}>
                            <StepLabel>{getStepLabel(index)}</StepLabel>
                            <StepContent>
                                {getStepContent(index)}
                                <div style={{ marginTop: "20px" }}>
                                    <Button
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                        variant="contained"
                                    >
                                        Voltar
                                    </Button>
                                    <Button
                                        onClick={activeStep === 2 ? handleSubmit : handleNext}
                                        variant="contained"
                                    >
                                        {activeStep === 2 ? "Calcular" : "Próximo"}
                                    </Button>
                                    {activeStep === 2 && (
                                        <Button
                                            sx={{
                                                marginLeft: "50px",
                                            }}
                                            onClick={resetFiltros}
                                            variant="contained"
                                        >
                                            Limpar
                                        </Button>
                                    )}

                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                <Box ref={resultadoCalculoSectionRef}>
                    {resultadoCalculo !== null && (
                        <div style={{ textAlign: "left", paddingTop: "80px", fontSize: "24px", fontWeight: "bolder" }}>
                            O valor estimado do aluguel é: {resultadoCalculo}
                        </div>
                    )}
                </Box>
            </Box>

            {contents}
        </div>
    );
}

export default CalculadoraAlugueisPage;