import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

interface AlertModalProps {
    isOpen: boolean;
    onClose: () => void;
    text: string;
}

const AlertModal2: React.FC<AlertModalProps> = ({ isOpen, onClose, text }) => {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle>Alerta</DialogTitle>
            <DialogContent>
                <p>{text}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Fechar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AlertModal2;