import classNames from 'classnames'
import React, { useState, useContext } from 'react';
import { DropZone } from '../../components/common/Dropzone';
import { FileList } from '../../components/common/Filelist';
import { Button } from '@mui/material';
import { AuthContext } from "../../contexts/auth";
import Loading from '../../components/common/Loading';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteIcon from '@mui/icons-material/Delete';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import { FichaFinanceira, Parcela } from '../../interfaces/IVendas';
import { Typography, Table, TableHead, TableBody, TableRow, TableCell, Divider } from '@mui/material';
import { formatarCPFCNPJ } from '../../utils/formatUtils';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Modal from '../../components/common/Modal';


const FichaFinanceiraPage = () => {
    const [isDropActive, setIsDropActive] = useState(false);
    const [files, setFiles] = useState<File[]>([]);
    const [ficha, setFicha] = useState<FichaFinanceira | null>(null);
    const { processaFichaFinanceira, geraBoletoFichaFinanceira } = useContext(AuthContext);

    const [severity, setSeverity] = useState("success");
    const [open, setOpen] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");
    const [loading, setLoading] = useState(false);

    const [modalOpen, setModalOpen] = useState(false);

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
        props,
        ref,
    ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    //const contents = open ? <Loading descricao={descricao} /> : renderEconomiasTable();

    const onDragStateChange = React.useCallback((dragActive: boolean) => {
        setIsDropActive(dragActive);
    }, []);

    const onFilesDrop = React.useCallback((files: File[]) => {
        setFiles(files);
    }, []);
    const removeAllFiles = () => {
        setFiles([]);
    };

    const handleSubmission = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setSeverity("success");
        setMsgAlert("Requisicao executada com Sucesso!");

        const formData = new FormData();

        formData.append('File', files[0]);
        try {
            let retorno = await processaFichaFinanceira(formData);
            setFicha(retorno);
        } catch (err: any) {
            console.log(err);
            setSeverity("error");
            setMsgAlert(err.response.data['mensagem']);
        }

        setLoading(false);
        setOpen(true);
    };

    const handleGerarBoleto = async (e: any) => {
        e.preventDefault();
        setLoading(true);
        setSeverity("success");        

        try {
            let retorno = await geraBoletoFichaFinanceira(ficha);
            setMsgAlert("Boletos Gerados com sucesso! " + retorno['mensagem']);
            setLoading(false);
            setModalOpen(true);
            //throw new Error('Ocorreu um erro!');
        } catch (err: any) {
            setLoading(false);
            setSeverity("error");
            //setMsgAlert('sdhf sdhf hsd fnsd fnsd fns dfn sdnf snd fnsd fnsd ');
            setMsgAlert(err.response.data['mensagem']);
            setModalOpen(true);
        }        
    };

    return (
        <>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === "success" ? "success" : "error"} sx={{ width: '100%' }}>
                    {msgAlert}
                </Alert>
            </Snackbar>

            {loading ? (<Loading descricao='Processando requisição' />) : ('')}
            <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
            
            <div className={classNames('dropZoneWrapper', {
                'dropZoneActive': isDropActive,
            })}>
                <DropZone
                    onDragStateChange={onDragStateChange}
                    onFilesDrop={onFilesDrop}
                >
                    <h2>Arraste seu arquivo .PDF<br /><CloudUploadIcon /></h2>

                    {files.length === 0 ? (
                        <h3>Sem arquivos</h3>
                    ) : (
                        <h3>Arquivos para Upload: {files.length}</h3>
                    )}

                    <FileList files={files} />
                </DropZone>

            </div>
            {!modalOpen ? (
                <>
                    <Button sx={{ "margin-top": "1%" }} variant="contained" endIcon={<UploadFileIcon />} onClick={handleSubmission}>
                        Processar Arquivo
                    </Button>
                    <Button sx={{ "margin-top": "1%", "margin-left": "1%" }} variant="contained" endIcon={<IntegrationInstructionsIcon />} onClick={handleGerarBoleto}>
                        Gerar Boleto(s)
                    </Button>
                    <Button sx={{ "margin-top": "1%", "margin-left": "1%", "backgroundColor": "red" }} variant="contained" endIcon={<DeleteIcon />} onClick={removeAllFiles}>
                        Remover arquivos
                    </Button>
                </>
            ) : ('')}
            
            <Divider sx={{ my: 2 }} />
            <div>
                <Typography variant="h4" gutterBottom>Informações da Ficha Financeira</Typography>

                <div>
                    <Typography variant="h6">Vendedor</Typography>
                    <Typography>Nome: {ficha?.vendedor.nome}</Typography>
                    <Typography>CPF/CNPJ: {formatarCPFCNPJ(ficha?.vendedor.cpf_cnpj)}</Typography>
                    {/* Demais campos do Vendedor */}
                </div>
                <Divider sx={{ my: 2 }} />
                <div>
                    <Typography variant="h6">Comprador</Typography>
                    <Typography>Nome: {ficha?.comprador.nome}</Typography>
                    <Typography>CPF/CNPJ: {formatarCPFCNPJ(ficha?.comprador.cpf_cnpj)}</Typography>
                    {/* Demais campos do Comprador */}
                </div>
                <Divider sx={{ my: 2 }} />
                <div>
                    <Typography variant="h6">Outras Informações</Typography>
                    <Typography>Responsável pela Comissão: {ficha?.responsavelcomissao}</Typography>
                    <Typography>Código CRM: {ficha?.codigo_crm}</Typography>
                    <Typography>Comissão Imobiliária: {ficha?.comissaoimobiliaria.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</Typography>
                </div>
                <Divider sx={{ my: 2 }} />
                <div>
                    <Typography variant="h6">Parcelas</Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Descrição</TableCell>
                                <TableCell>Participante</TableCell>
                                <TableCell>CPF/CNPJ</TableCell>
                                <TableCell>Data de Vencimento</TableCell>
                                <TableCell>Valor</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {ficha?.parcelas.map((parcela: Parcela) => (
                                <TableRow key={parcela.id}>
                                    <TableCell>{parcela.id}</TableCell>
                                    <TableCell>{parcela.descricao}</TableCell>
                                    <TableCell>{parcela.participante}</TableCell>
                                    <TableCell>{formatarCPFCNPJ(parcela.cpf_cnpj)}</TableCell>
                                    <TableCell>{parcela.datavencimento}</TableCell>
                                    <TableCell>{parcela.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </div>
        </>
    );
}

export default FichaFinanceiraPage;