import React, { useState, useContext } from 'react';
import { Typography } from '@mui/material';
import { AuthContext } from '../../../contexts/auth';
import { LeadsImoveis } from '../../../interfaces/ISite';
import Relatorio from '../../../components/common/Relatorio';

const RelatorioLeadsPage: React.FC = () => {
    const { exportaListaLeadsSite, buscaListaLeadsSite } = useContext(AuthContext);
    const [ficha, setFicha] = useState<LeadsImoveis[]>([]);

    return (
        <div>
            <Typography variant="h6">Relatório Leads</Typography>
            <Relatorio
                busca={buscaListaLeadsSite}
                exporta={exportaListaLeadsSite}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"Leads"}
            />
        </div>
    );
};

export default RelatorioLeadsPage;