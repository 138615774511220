import React from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import FilterFormBuscaClientes from '../../pages/condominio/BuscarClientes/FilterFormBuscaClientes';

interface BuscaClientesFormProps {
    handleSubmit: (event: React.FormEvent) => void;
    handleChangeItensPagina: (event: SelectChangeEvent<number>) => void;
    handleGenerateExcel: (event: React.FormEvent) => void;
    itensPagina: number;
    formData: any;
    setFormData: (data: any) => void;
}

const BuscaClientesForm: React.FC<BuscaClientesFormProps> = ({
    handleSubmit,
    handleChangeItensPagina,
    handleGenerateExcel,
    itensPagina,
    formData,
    setFormData,
}) => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleSelectChange = (event: SelectChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ display: "flex" }}>
                    <FilterFormBuscaClientes
                        handleChange={handleChange}
                        handleSelectChange={handleSelectChange}
                        formData={formData}
                    />
            </div>
            <Grid container sx={{ mt: 2 }} >
                <Grid item xs={2}>
                    <FormControl
                        sx={{
                            width: '100%',
                            marginTop: '10px',
                            marginRight: '10px'
                        }}>

                        <InputLabel className="customLabel">Usuários por página</InputLabel>
                        <Select
                            value={itensPagina}
                            onChange={handleChangeItensPagina}
                            className="customSelect"
                            variant="filled"
                            type="number"
                            inputProps={{
                                name: 'itensPagina',
                                id: 'itens-pagina',
                            }}
                        >
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                            <MenuItem value={50}>50</MenuItem>
                            <MenuItem value={100}>100</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={10}>
                    <Button type="submit" sx={{ margin: '10px', float: 'right', width: '20%', height: 50 }} variant="contained">
                        Buscar
                    </Button>
                    <Button color="secondary" sx={{ margin: '10px', float: 'right', width: '20%', height: 50 }} variant="contained" onClick={handleGenerateExcel}>
                        Exportar
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default BuscaClientesForm;