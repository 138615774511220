import React, { useEffect, useState } from 'react';
import { AlertProps, Divider, SelectChangeEvent, Snackbar, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import TableDataIntegracao from '../../pages/Seguros/BuscaIntegracaoBetalabs/BuscaIntegracaoTable';
import TableDataPessoas from '../../pages/Seguros/BuscaIntegracaoBetalabs/BuscaPessoasTable';
import Pagination from './Pagination';
import BuscaIntegracaoForm from './BuscaIntegracoesForm';

interface IntegracoesBetalabProps {
    busca: any;
    ficha: any;
    setFicha(ficha: any): void;
}

const BuscarIntegracoesBetalabs: React.FC<IntegracoesBetalabProps> = ({
    busca,
    ficha,
    setFicha,
}) => {
    const [severity, setSeverity] = useState('success');
    const [msgAlert, setMsgAlert] = useState('');
    const [loading, setLoading] = useState(false);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensPagina, setItensPagina] = useState(10);
    const [open, setOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState<{ TipoBusca?: number }>({ TipoBusca: 0 });
    const [tipoBusca, setTipoBusca] = useState<number>(0); 

    useEffect(() => {
        if (ficha.length !== 0) {
            buscar(formData, tipoBusca, paginaAtual, itensPagina);
        }
    }, [paginaAtual, itensPagina]);

    useEffect(() => {
        if (formData.TipoBusca !== undefined && ficha.length > 0) {
            buscar(formData, tipoBusca,  1, itensPagina, true);
        }
    }, [formData.TipoBusca]);

    const buscar = async (filtros: any, tipoBusca: number, paginaAtual: number, itensPagina: number, msg = false) => {
        try {
            setLoading(true);
            if (filtros['Codigo_condominio'] === undefined) {
                filtros['Codigo_condominio'] = null;
            }
            let retorno = await busca(filtros, tipoBusca, paginaAtual, itensPagina);
            setFicha(retorno['Itens']);
            setTotalPaginas(retorno['TotalPaginas']);
            setTotalRegistros(retorno['TotalItens']);
            if (msg === true) {
                if(retorno['Itens'].length == 0){
                    setSeverity("error");
                    setMsgAlert( "Condomínio não integrado.");
                }
                else{
                    setSeverity("success");
                    setMsgAlert(retorno['TotalItens'] + " registros encontrados.");
                }
                setOpen(true);
            }
        } catch (err: any) {
            console.log(err.response.data);
            setSeverity("error");
            setMsgAlert(err.response.data);
            setOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setPaginaAtual(1);
        buscar(formData, tipoBusca, 1, itensPagina, true);
    };

    return (
        <>
            <Divider sx={{ my: 2 }} />
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
                    {msgAlert}
                </Alert>
            </Snackbar>
            <BuscaIntegracaoForm
                handleSubmit={handleSubmit}
                handleChangeItensPagina={(event: SelectChangeEvent<number>) => setItensPagina(Number(event.target.value))}
                itensPagina={itensPagina}
                formData={formData}
                setFormData={setFormData}
                setTipoBusca={setTipoBusca}
            />
            <div>
                <Divider sx={{ my: 2 }} />
                <div>
                    {formData.TipoBusca === 0 ? (
                        <TableDataIntegracao ficha={ficha} loading={loading} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    ) : (
                        <TableDataPessoas ficha={ficha} loading={loading} modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
                    )}
                </div>
                <Pagination
                    paginaAtual={paginaAtual}
                    totalPaginas={totalPaginas}
                    PaginaAnterior={() => setPaginaAtual(paginaAtual - 1)}
                    ProximaPagina={() => setPaginaAtual(paginaAtual + 1)}
                    IrParaPagina={(pageNumber) => setPaginaAtual(pageNumber)}
                    IrParaPrimeiraPagina={() => setPaginaAtual(1)}
                    IrParaUltimaPagina={() => setPaginaAtual(totalPaginas)}
                />
                <Typography>Registros: {totalRegistros}</Typography>
            </div>
        </>
    );
};

export default BuscarIntegracoesBetalabs;