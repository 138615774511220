import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, CssBaseline, Grid, Snackbar, TextField, ThemeProvider, Typography, createTheme } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Copyright } from '.';
import { AuthContext } from '../../contexts/auth';

const theme = createTheme();

const RecuperarSenhaPage = () => {
  const { redefinicaoSenha } = useContext(AuthContext);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState<string | null>();

  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  
  const [msgAlert, setMsgAlert] = useState("");
  const [open, setOpen] = useState(false);
  const [severity, setSeverity] = useState("success");

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
    ) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
      if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  
  const validatePassword = () => {
    if (!password) {
      setPasswordError('Este campo é obrigatório');
    } else {
      setPasswordError('');
    }
  };

  const validateConfirmPassword = () => {
    if (!confirmPassword) {
      setConfirmPasswordError('Este campo é obrigatório');
    } else if (confirmPassword !== password) {
      setConfirmPasswordError('As senhas não coincidem');
    } else {
      setConfirmPasswordError('');
    }
  };

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const tokenR = urlSearchParams.get('token')
    setToken(tokenR)

    if (tokenR === null) {
      window.location.href = '/login';
    } else {
      localStorage.setItem("token-redefinir-senha", tokenR);
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    validatePassword();
    validateConfirmPassword();
    if (passwordError || confirmPasswordError) {
      return;
    }
    setLoading(true);
    try {
      await redefinicaoSenha(password, confirmPassword);
      setSeverity("success");
      setMsgAlert("Senha alterada com sucesso");
      setOpen(true);
      localStorage.removeItem("token-redefinir-senha");
      window.location.href = '/login';
    } catch {
      setSeverity("error");
      setMsgAlert("Algo deu errado");
      setOpen(true);
    } finally {
      setLoading(false);
    }
  }

  return token ? (
    <ThemeProvider theme={theme}>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity === "success" ? "success" : "error"} sx={{ width: '100%' }}>
          {msgAlert}
        </Alert>
      </Snackbar>
      <Grid component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid item>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img
              src="https://storage.googleapis.com/storage_geral/imagens/logo/logo.png"
              alt="Logo"
              style={{ width: '20%', height: 'auto' }}>
            </img>

            <Typography component="h1" variant="h5" sx={{ my: 3 }}>
              GDocs - Recuperar Senha
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Nova Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onBlur={validatePassword}
                    value={password} onChange={(e) => setPassword(e.target.value)}
                  />
                  {passwordError && <Typography style={{ color: 'red' }}>{passwordError}</Typography>}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="confirm-password"
                    label="Confirmar Nova Senha"
                    type="password"
                    id="confirm-password"
                    autoComplete="current-password"
                    onBlur={validateConfirmPassword}
                    value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  {confirmPasswordError && <Typography style={{ color: 'red' }}>{confirmPasswordError}</Typography>}
                </Grid>
              </Grid>
              {loading ? (<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
                <CircularProgress size={24} />
              </Box>) : (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Alterar Senha
                </Button>)}
            </Box>
            <Grid container>
            </Grid>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  ) : null;
}

export default RecuperarSenhaPage;