import React from 'react';
import { Grid, Typography } from '@mui/material';
import Loading from '../../../components/common/Loading';
import Modal from '../../../components/common/Modal';
import '../../../components/common/Relatorio.css'
import { IRelatorioEmailAniversario } from '../interfaces/IAniversarios';

interface TableDataProps {
  ficha: IRelatorioEmailAniversario[];
  loading: boolean;
  msgAlert: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
}

const TableData: React.FC<TableDataProps> = ({ ficha, loading, msgAlert, modalOpen, handleCloseModal }) => {
  const fixDateTime = (dateTime: string) => {
    const date = new Date(dateTime);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
  }

  const handleDateTime = (date: string) => date ? (date.toString().substring(0, 10) === '0001-01-01' ? "" : fixDateTime(date.toString())) : "";
  
  return (
    <div className="responsive-table-container">
      {loading ? <Loading /> : ''}
      <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
      <Grid container spacing={2}>

        {ficha.map((customer: IRelatorioEmailAniversario) => (
          <>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom>
                <b>{customer.Nome_pessoa}</b>
              </Typography>
              <Typography variant="h6" className='low-contrast bold'>
                DATA DE NASCIMENTO: {customer.Data_aniversario}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom>
                <b>Perfil:</b> {customer.Cliente_ns ? "Síndico NS ou Síndico ou outros" : "Cliente"}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <b>Email Enviado:</b> {customer.Email_Enviado ? "Sim" : "Não"}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <b>Email:</b> {customer.Email}
              </Typography>
              <Typography variant="h6" gutterBottom>
                <b>Telefone:</b> {customer.Telefone}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom>
                <b>Email</b>
              </Typography>
              <Typography variant="h6" gutterBottom>
                Data/Hora de Envio: {handleDateTime(customer.Datahora_Envio)}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Data/Hora de Entregue: {handleDateTime(customer.Datahora_Entregue)}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Data/Hora de Abertura: {handleDateTime(customer.DataHora_Abertura)}
              </Typography>
              <Typography variant="h6" gutterBottom>
                Data/Hora do Click: {handleDateTime(customer.Datahora_Clicou)}
              </Typography>
            </Grid>
          </>

        ))}
      </Grid>

    </div>
  );
};

export default TableData;
