import classNames from 'classnames'
import React, { useState, useContext } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import DeleteIcon from '@mui/icons-material/Delete';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { DropZone } from '../../components/common/Dropzone';
import { FileList } from '../../components/common/Filelist';
import { Button } from '@mui/material';
import { AuthContext } from "../../contexts/auth";
import Loading from '../../components/common/Loading';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';

interface RetornoWS {
    status: number;
    descricao: string;
}

interface Economia {
    Codigo_Condominio: string;
    Codigo_Bloco: string;
    Descricao: string;
    Tipo: string;
    Fracao: string;
    Tipo_Extrato: string;
    Emite_Etiqueta: string;
    Codigo_Imobiliaria: string;
    Codigo_Imovel: string;
    Exporta_Locacao: string;
    Boleto_Email: string;
    Isentar_Taxa_Porte: string;
    Associar_Advogado: string;
    Nome_Pessoa: string;
    Tipo_Pessoa: string;
    CPF_CNPJ: string;
    Celular: string;
    Local_Endereco_Cobranca: string;
    Local_Endereco_Correspondencia: string;
    Email_Pessoa: string;
    Tipo_Endereco_Pessoa: string;
    CEP_Pessoa: string;
    Tipo_Logradouro_Pessoa: string;
    Logradouro_Pessoa: string;
    Numero_Pessoa: string;
    Complemento_Pessoa: string;
    Bairro_Pessoa: string;
    Cidade_Pessoa: string;
    UF_Pessoa: string;
    Retorno_WS: RetornoWS;
}

type Props = {};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function StatusIcon(ret: RetornoWS) {
    if (ret == null) {
        return <Tooltip title="N&atilde;o Processado"><CheckCircleIcon color="disabled"></CheckCircleIcon></Tooltip>
    }

    if (ret.status === 200) {
        return <Tooltip title={ret.descricao}><CheckCircleIcon color="success"></CheckCircleIcon></Tooltip>
    } else {
        return <Tooltip title={ret.descricao}><CheckCircleIcon color="error"></CheckCircleIcon></Tooltip>
    }
}


const ImportarEconomiasPage = (props: Props) => {

    const [isDropActive, setIsDropActive] = useState(false)
    const [files, setFiles] = useState<File[]>([])
    const [economias, setEconomias] = useState<Economia[]>([])
    const { processaArquivoEconomiaImobiliar, importaEconomiaImobiliar } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [descricao, setDescricao] = useState('');

    const handleSubmission = async (e: any) => {
        setOpen(true);
        const formData = new FormData();

        formData.append('File', files[0]);
        try {
            let retorno = await processaArquivoEconomiaImobiliar(formData);
            setEconomias(retorno);
        } catch (err: any) {
            setOpen(false);
            console.log(err.response.data);
            //setSeverity("error");
            //setMsgAlert(err.response.data['mensagem']);
        }

        setOpen(false);
    };

    const handleImportarUnidades = async (e: any) => {
        setOpen(true);
        let ec = JSON.parse(JSON.stringify(economias));
        setDescricao('');
        let i = -1;
        let total = ec.length;
        for (const economia of ec) {
            i++;
            setDescricao(' Importando ' + (i + 1) + ' de ' + total);
            try {
                const json = await importaEconomiaImobiliar(economia);

                const ret = {} as RetornoWS;

                ret.status = json.sucesso ? 200 : 0;
                ret.descricao = json.mensagem;
                ec[i].Retorno_WS = ret;
               
                setEconomias(ec);
            } catch (err: any) {
                setOpen(false);
                console.log(err);
                console.log(err.response.data);
                //setSeverity("error");
                //setMsgAlert(err.response.data['mensagem']);
            }
        };

        setOpen(false);
    };    

    //const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

    const renderEconomiasTable = (): JSX.Element => {
        return (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600, "margin-top": "1%" }} size="small" aria-label="a dense table" >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell >#</StyledTableCell>
                            <StyledTableCell >In</StyledTableCell>
                            <StyledTableCell >C&oacute;d. Cond.</StyledTableCell>
                            <StyledTableCell >Nome Pessoa</StyledTableCell>
                            <StyledTableCell >Email</StyledTableCell>
                            <StyledTableCell >Celular</StyledTableCell>
                            <StyledTableCell >CPF/CNPJ</StyledTableCell>
                            <StyledTableCell >Logradouro</StyledTableCell>
                            <StyledTableCell >Num. Log.</StyledTableCell>
                            <StyledTableCell >Compl.</StyledTableCell>
                            <StyledTableCell >CEP</StyledTableCell>
                            <StyledTableCell >Bairro</StyledTableCell>
                            <StyledTableCell >Cidade</StyledTableCell>
                            <StyledTableCell >UF</StyledTableCell>
                            <StyledTableCell >Unidade</StyledTableCell>
                            <StyledTableCell >Tipo</StyledTableCell>
                            <StyledTableCell >Bloco</StyledTableCell>
                            <StyledTableCell >Fra&ccedil;&atilde;o</StyledTableCell>
                            <StyledTableCell >Boleto Email</StyledTableCell>
                            <StyledTableCell >Ass. Adv</StyledTableCell>
                            <StyledTableCell >C&oacute;d. Imob.</StyledTableCell>
                            <StyledTableCell >C&oacute;d. Im&oacute;vel</StyledTableCell>
                            <StyledTableCell >Emite Etq.</StyledTableCell>
                            <StyledTableCell >Exp. Loc.</StyledTableCell>
                            <StyledTableCell >Isen. Tx. Porte</StyledTableCell>
                            <StyledTableCell >End. Cob.</StyledTableCell>
                            <StyledTableCell >End. Corr.</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {economias.map((cur, index) =>
                            <StyledTableRow key={index}>
                                <StyledTableCell>{StatusIcon(cur.Retorno_WS)}</StyledTableCell>
                                <StyledTableCell>{index}</StyledTableCell>
                                <StyledTableCell>{cur.Codigo_Condominio}</StyledTableCell>
                                <StyledTableCell>{cur.Nome_Pessoa}</StyledTableCell>
                                <StyledTableCell>{cur.Email_Pessoa}</StyledTableCell>
                                <StyledTableCell>{cur.Celular}</StyledTableCell>
                                <StyledTableCell><TextField
                                    fullWidth 
                                    hiddenLabel
                                    id="CPF_CNPJ"
                                    name="CPF_CNPJ"
                                    defaultValue=""
                                    size="small"
                                    variant="standard"
                                    value={cur.CPF_CNPJ}
                                    sx={{
                                        width: '120px',
                                        fontSize: 'small'
                                    }}
                                    onChange={(e) => onChangeText((e.target as HTMLInputElement), index)}
                                /></StyledTableCell>
                                <StyledTableCell>{cur.Logradouro_Pessoa}</StyledTableCell>
                                <StyledTableCell>{cur.Numero_Pessoa}</StyledTableCell>
                                <StyledTableCell>{cur.Complemento_Pessoa}</StyledTableCell>
                                <StyledTableCell>{cur.CEP_Pessoa}</StyledTableCell>
                                <StyledTableCell>{cur.Bairro_Pessoa}</StyledTableCell>
                                <StyledTableCell>{cur.Cidade_Pessoa}</StyledTableCell>
                                <StyledTableCell>{cur.UF_Pessoa}</StyledTableCell>
                                <StyledTableCell>{cur.Descricao}</StyledTableCell>
                                <StyledTableCell>{cur.Tipo}</StyledTableCell>
                                <StyledTableCell>{cur.Codigo_Bloco}</StyledTableCell>
                                <StyledTableCell>{cur.Fracao}</StyledTableCell>
                                <StyledTableCell><input name='Boleto_Email' key={index} onChange={(e) => onChange((e.target as HTMLInputElement), index)} type="checkbox" checked={cur.Boleto_Email === 'S'} value={index} /></StyledTableCell>
                                <StyledTableCell><input name='Associar_Advogado' key={index} onChange={(e) => onChange((e.target as HTMLInputElement), index)} type="checkbox" checked={cur.Associar_Advogado === 'S'} value={index} /></StyledTableCell>
                                <StyledTableCell>{cur.Codigo_Imobiliaria}</StyledTableCell>
                                <StyledTableCell>{cur.Codigo_Imovel}</StyledTableCell>
                                <StyledTableCell><input name='Emite_Etiqueta' key={index} onChange={(e) => onChange((e.target as HTMLInputElement), index)} type="checkbox" checked={cur.Emite_Etiqueta === 'S'} value={index} /></StyledTableCell>
                                <StyledTableCell><input name='Exporta_Locacao' key={index} onChange={(e) => onChange((e.target as HTMLInputElement), index)} type="checkbox" checked={cur.Exporta_Locacao === 'S'} value={index} /></StyledTableCell>
                                <StyledTableCell><input name='Isentar_Taxa_Porte' key={index} onChange={(e) => onChange((e.target as HTMLInputElement), index)} type="checkbox" checked={cur.Isentar_Taxa_Porte === 'S'} value={index} /></StyledTableCell>
                                <StyledTableCell>{cur.Local_Endereco_Cobranca}</StyledTableCell>
                                <StyledTableCell>{cur.Local_Endereco_Correspondencia}</StyledTableCell>
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer >
        );
    }

    const onChange = React.useCallback((e: HTMLInputElement, index: number) => {
        let ec = JSON.parse(JSON.stringify(economias));
        let economia = ec[index];
        switch (e.name) {
            case 'Boleto_Email':
                economia.Boleto_Email = e.checked ? 'S' : 'N';
                break;
            case 'Associar_Advogado':
                economia.Associar_Advogado = e.checked ? 'S' : 'N';
                break;
            case 'Emite_Etiqueta':
                economia.Emite_Etiqueta = e.checked ? 'S' : 'N';
                break; 
            case 'Exporta_Locacao':
                economia.Exporta_Locacao = e.checked ? 'S' : 'N';
                break; 
            case 'Isentar_Taxa_Porte':
                economia.Isentar_Taxa_Porte = e.checked ? 'S' : 'N';
                break;
        }
        
        ec[index] = economia;  
        setEconomias(ec);
    }, [economias])    


    const onChangeText = React.useCallback((e: HTMLInputElement, index: number) => {
        let ec = JSON.parse(JSON.stringify(economias));
        let economia = ec[index];
        switch (e.name) {
            case 'CPF_CNPJ':
                economia.CPF_CNPJ = e.value;
                break;
        }

        ec[index] = economia;
        setEconomias(ec);
    }, [economias])  

    const contents = open ? <Loading descricao={ descricao } /> : renderEconomiasTable();        

    const onDragStateChange = React.useCallback((dragActive: boolean) => {
        setIsDropActive(dragActive);
    }, [])

    const onFilesDrop = React.useCallback((files: File[]) => {
        setFiles(files);
    }, []) 

    const removeAllFiles = () => {
        setFiles([]);
    }

    return (
        <>
            <div className={classNames('dropZoneWrapper', {
                'dropZoneActive': isDropActive,
            })}>
                <DropZone
                    onDragStateChange={onDragStateChange}
                    onFilesDrop={onFilesDrop}
                >
                    <h2>Arraste seu arquivo .XLSX<br /><CloudUploadIcon /></h2>

                    {files.length === 0 ? (
                        <h3>Sem arquivos</h3>
                    ) : (
                        <h3>Arquivos para Upload: {files.length}</h3> 
                    )}

                    <FileList files={files} />
                </DropZone>
                
            </div>
            <Button sx={{ "margin-top": "1%" }} variant="contained" endIcon={<UploadFileIcon />} onClick={handleSubmission}>
                Processar Arquivo
            </Button>
            <Button sx={{ "margin-top": "1%", "margin-left": "1%" }} variant="contained" endIcon={<IntegrationInstructionsIcon />} onClick={handleImportarUnidades}>
                Importar Unidades
            </Button>
            <Button sx={{ "margin-top": "1%", "margin-left": "1%", "backgroundColor": "red" }} variant="contained" endIcon={<DeleteIcon />} onClick={removeAllFiles}>
                Remover arquivos
            </Button>

            {contents}            
        </>
    );
}

export default ImportarEconomiasPage;