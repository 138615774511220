import { FormControl, TextField } from "@mui/material";

interface FilterFormCDLProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    formData: any;
}

const FilterFormCDL: React.FC<FilterFormCDLProps> = ({ handleChange, formData }) => {
    return (
        <>
            <FormControl
                sx={{
                    width: '49%',
                    minWidth: '40%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="ID Condominio"
                    name="id_condominio"
                    value={formData.id_condominio}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>
            <FormControl
                sx={{
                    width: '49%',
                    minWidth: '40%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="CPF/CNPJ"
                    name="cpf_cnpj"
                    value={formData.cpf_cnpj}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl >
        </>
    );
};


export default FilterFormCDL;