import React, { useState, useContext } from 'react';
import { Typography } from '@mui/material';
import { AuthContext } from '../../../contexts/auth';
import { CDL } from '../../../interfaces/ICDL';
import Relatorio from '../../../components/common/Relatorio';

const RelatorioCDLPage: React.FC = () => {
    const [ficha, setFicha] = useState<CDL[]>([]);
    const { buscaListaCDL } = useContext(AuthContext);
    const { exportaListaCDL } = useContext(AuthContext);

    return (
        <div>
            <Typography variant="h6">Relatório CDL</Typography>
            <Relatorio
                busca={buscaListaCDL}
                exporta={exportaListaCDL}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"CDL"}
            />
        </div>
    );
};

export default RelatorioCDLPage;