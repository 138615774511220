import React, { useState, useContext, useRef } from 'react';
import { Paper, Button, TextField, Grid, List, ListItem, ListItemText, Typography, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { AuthContext } from "../../contexts/auth";
import Loading from '../../components/common/Loading';
import Modal from '../../components/common/Modal2';
import { DadosNotificacao, DadosNotificacaoDTO } from '../../interfaces/IERemessa';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import axios from 'axios';

const StyledListItem = styled(ListItem)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    '&:nth-of-type(even)': {
        backgroundColor: theme.palette.background.paper,
    },
    marginBottom: theme.spacing(1), // Adiciona uma margem inferior
}));

const PrimaryText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
}));

const SecondaryText = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

function formatDateCell(date: Date | string): XLSX.CellObject {
    // Realize aqui a formatação da data conforme desejado
    const formattedDate = formatarDataHora(date);
    return { v: formattedDate, t: 's' }; // 's' indica que é uma célula de string
  }

export function exportarParaExcel(dados: DadosNotificacaoDTO[], nomeArquivo: string) {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(dados);

    // Alterar o nome das colunas
    worksheet['A1'].v = 'Categoria';
    worksheet['B1'].v = 'Cód. Condominio';
    worksheet['C1'].v = 'Nome do Anexo';
    worksheet['D1'].v = 'Email';
    worksheet['E1'].v = 'Unidade';
    worksheet['F1'].v = 'Bloco';
    worksheet['G1'].v = 'Envio Email';
    worksheet['H1'].v = 'Abertura Email';
    worksheet['I1'].v = 'Falha Envio Email';
    worksheet['J1'].v = 'Envio SMS';
    worksheet['K1'].v = 'Abertura SMS';
    worksheet['L1'].v = 'Falha Envio SMS';

    // Formatar as células de data conforme desejado
    dados.forEach((item, index) => {
        if (item.dataHoraEnvioEmail) {
            const cellRef = XLSX.utils.encode_cell({ r: index + 1, c: 6 }); // Coluna G
            const formattedCell = formatDateCell(item.dataHoraEnvioEmail);            
            worksheet[cellRef] = formattedCell;
        }
        if (item.dataHoraAberturaEmail) {
            const cellRef = XLSX.utils.encode_cell({ r: index + 1, c: 7 });
            const formattedCell = formatDateCell(item.dataHoraAberturaEmail);            
            worksheet[cellRef] = formattedCell;
        }
        if (item.dataHoraRejeitadoEmail) {
            const cellRef = XLSX.utils.encode_cell({ r: index + 1, c: 8 });
            const formattedCell = formatDateCell(item.dataHoraRejeitadoEmail);            
            worksheet[cellRef] = formattedCell;
        }
        if (item.dataHoraEnvioSms) {
            const cellRef = XLSX.utils.encode_cell({ r: index + 1, c: 9 });
            const formattedCell = formatDateCell(item.dataHoraEnvioSms);            
            worksheet[cellRef] = formattedCell;
        }
        if (item.dataHoraAberturaSms) {
            const cellRef = XLSX.utils.encode_cell({ r: index + 1, c: 10 });
            const formattedCell = formatDateCell(item.dataHoraAberturaSms);            
            worksheet[cellRef] = formattedCell;
        }
        if (item.dataHoraRejeitadoSms) {
            const cellRef = XLSX.utils.encode_cell({ r: index + 1, c: 11 });
            const formattedCell = formatDateCell(item.dataHoraRejeitadoSms);            
            worksheet[cellRef] = formattedCell;
        }


        // Repita o processo para as demais células de data
    });

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Dados');

    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    saveAs(data, nomeArquivo);
}

function formatarDataHora(dataHora: string | Date | null) {
    if (dataHora === null) {
        return '';
    }
    const formatoDataHora = 'DD/MM/YYYY HH:mm:ss';
    return moment(dataHora).format(formatoDataHora);
}

const options = [
    { value: '0', label: 'TIPO DE DOCUMENTO' },
    { value: '160', label: 'CONVOCAÇÕES' }, 
    { value: '344', label: 'CNDs' },
    { value: '146', label: 'ATAS' },
    { value: '165', label: 'CIRCULARES' }
];

const ERemessa: React.FC = () => {
    const currentDate = new Date();
    const campoCod = useRef<HTMLInputElement>(null);
    const [dados, setDados] = useState<DadosNotificacao[]>([]);

    const [loading, setLoading] = useState(false);
    const [texto, setTexto] = useState('');
    const [dataInicio, setDataInicio] = useState<Date | null>(currentDate);
    const [dataFim, setDataFim] = useState<Date | null>(currentDate);
    const { filtraERemessa, exportaERemessa } = useContext(AuthContext);
    const [selectedOption, setSelectedOption] = useState('0');
    const [modalOpen, setModalOpen] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");

    const handleSelectChange = (event: any) => {
        setSelectedOption(event.target.value);
    };    

    const handleButtonClick = async () => {
        if (texto.trim() === '') {
            setMsgAlert('Preencha o Código do Condomínio');
            setModalOpen(true);
            campoCod.current?.focus();
            return;
        }

        setLoading(true);
        const queryParams = {
            "Cod_Condominio": texto,
            "Cod_Categoria": selectedOption,
            "Data_Inicio": dataInicio,
            "Data_Fim": dataFim
        };

        let retorno = await filtraERemessa(queryParams);
        setDados(retorno);
        setLoading(false);
    };

    const handleButtonExportarClick = async () => {
        const queryParams = {
            "Cod_Condominio": texto,
            "Cod_Categoria": selectedOption,
            "Data_Inicio": dataInicio,
            "Data_Fim": dataFim
        };

        let retorno = await exportaERemessa(queryParams);
        exportarParaExcel(retorno, 'dados_notificacao.xlsx');
    };

    const handleButtonExecutarClick = async () => {
        try {
            setLoading(true);
            await axios.get(`https://api.gdocs.guarida.com.br/api/ERemessa/GerarNotificacao?token=5hJuuyJki5YmYxRTdbSZLFZABwBeoHkrAh95mMz2mhUHBNKGD5gp8LLRFTe8n73W`);
            setLoading(false);
           
          } catch (error) {
            console.error('Error executar Envio:', error);
            setLoading(false);
          }
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <>  
        <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
        <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
        <TextField
                        label="Código Condomínio"
          value={texto}
          onChange={e => setTexto(e.target.value)}
          fullWidth
          inputRef={campoCod}
        />
                    
        </Grid>
                <Grid item xs={12} sm={6}>
                    <Select fullWidth value={selectedOption} onChange={handleSelectChange} >
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
                        label="Data de Início"
          type="date"
                        value={dataInicio ? dataInicio.toISOString().substring(0, 10) : ''}
          onChange={e => setDataInicio(new Date(e.target.value))}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label="Data Fim"
          type="date"
                        value={dataFim ? dataFim.toISOString().substring(0, 10) : ''}
          onChange={e => setDataFim(new Date(e.target.value))}
          fullWidth
        />
      </Grid>
                <Grid item xs={12} sm={6}>
        <Button variant="contained" color="primary" onClick={handleButtonClick}>
          Pesquisar
                    </Button> &nbsp;
                    <Button variant="contained" color="primary" onClick={handleButtonExportarClick}>
                        Exportar
                    </Button>&nbsp;
                    <Button variant="contained" color="secondary" onClick={handleButtonExecutarClick}>
                        Enviar ERemessa
                    </Button>
                    
                </Grid>
    </Grid>
            {loading ? (
                <Loading descricao="Buscando as informa&ccedil;&otilde;es"/>
            ) : (
            <Paper>
                <List>
                    {dados.map((dado, index) =>
                        dado.notificacoes.map(notificacao => (
                            <StyledListItem
                                key={notificacao.id_Notificacao}
                                style={{ backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'transparent' }}
                            >
                                <ListItemText
                                    primary={
                                        <>
                                            <PrimaryText variant="subtitle1">{dado.categoria}</PrimaryText>
                                        </>
                                    }
                                    secondary={
                                        <>
                                            <PrimaryText variant="subtitle1">{dado.nomeAnexo}</PrimaryText>
                                            <SecondaryText variant="body2">{dado.idCondominio} - {dado.nomeCondominio}</SecondaryText>
                                            <PrimaryText variant="body2">Unidade: {notificacao.economiaCondominio.descricao} - {notificacao.economiaCondominio.bloco}</PrimaryText>
                                        </>
                                    }
                                />
                                
                                <ListItemText
                                    
                                    secondary={
                                        notificacao.logsNotificacao.map(log => (
                                            <div key={log.iD_LogNotificacao}>
                                                {log.tipo_Log === 0 && (
                                                    <>
                                                        <PrimaryText variant="subtitle1">Email</PrimaryText>
                                                        <Typography variant="body2">Data/Hora de Envio: {formatarDataHora(log.datahora_Envio)}</Typography>
                                                        <Typography variant="body2">Data/Hora de Entregue: {formatarDataHora(log.datahora_Entregue)}</Typography>
                                                        <Typography variant="body2">Data/Hora de Abertura:{formatarDataHora(log.datahora_Abertura)}</Typography>
                                                        <Typography variant="body2">Data/Hora de Click: {formatarDataHora(log.datahora_Clicou)}</Typography>
                                                        {/* Adicione mais informações do log de notificação conforme necessário */}
                                                    </>
                                                )}
                                                {log.tipo_Log === 1 && (
                                                    <>
                                                        <PrimaryText variant="subtitle1">SMS</PrimaryText>
                                                        <Typography variant="body2">Data/Hora de Envio: {formatarDataHora(log.datahora_Envio)}</Typography>
                                                        <Typography variant="body2">Data/Hora de Entregue: {formatarDataHora(log.datahora_Entregue)}</Typography>
                                                        <Typography variant="body2">Data/Hora de Abertura:{formatarDataHora(log.datahora_Abertura)}</Typography>
                                                        <Typography variant="body2">Data/Hora de Click: {formatarDataHora(log.datahora_Clicou)}</Typography>
                                                        {/* Adicione mais informações do log de notificação conforme necessário */}
                                                    </>
                                                )}
                                            </div>
                                        ))
                                    }
                                />
                            </StyledListItem>
                        ))
                    )}
                </List>
                </Paper>
            )}
        </>
    );
};

export default ERemessa;
