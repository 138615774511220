import { Outlet, useLocation  } from "react-router-dom";
import { Box } from "@mui/material";
import colorConfigs from "../../configs/colorConfigs";
import sizeConfigs from "../../configs/sizeConfigs";
import Sidebar from "../common/Sidebar";
import Topbar from "../common/Topbar";
import { useState, useMemo} from "react";
import usePageTitle from "../../routes/usePageTitle";

const MainLayout = () => {
  const [open, setOpen] = useState(true);
  const pageTitle = usePageTitle();

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <Topbar open={open} toggleDrawer={toggleDrawer} pageTitle={pageTitle}/>

      <Box sx={{ display: "flex" }}>
        <Box
          component="nav"
          sx={{
            width: "80px",
            flexShrink: 0,
            ...(open && {
              width: sizeConfigs.sidebar.width,
            })
          }}
        >
          <Sidebar open={open} toggleDrawer={toggleDrawer} />
        </Box>

        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            marginTop: "64px",
            width: `calc(100% - ${sizeConfigs.sidebar.width})`,
            backgroundColor: colorConfigs.mainBg
          }}
        >
          <Outlet />
        </Box>
      </Box>


    </>

  );
};

export default MainLayout;