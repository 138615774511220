import React, { useState, useContext } from 'react';
import { Typography } from '@mui/material';
import { AuthContext } from '../../../contexts/auth';
import { BuscaIntegracao } from '../../../interfaces/IBuscaIntegracoesBetalabs';
import BuscarIntegracoesBetalabs from '../../../components/common/BuscaIntegracoes';
const BuscarIntegracaoPage: React.FC = () => {
    const [ficha, setFicha] = useState<BuscaIntegracao[]>([]);
    const { buscaIntegracaoBetalabs } = useContext(AuthContext);
    return (
        <div>
            <Typography variant="h6">Buscar Integrações</Typography>
            <BuscarIntegracoesBetalabs
                busca={buscaIntegracaoBetalabs}
                ficha={ficha}
                setFicha={setFicha}
            />
        </div>
    );
};
export default BuscarIntegracaoPage;