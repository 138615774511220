import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Link, Routes, useParams, useNavigate } from 'react-router-dom';
import { Button, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, AppBar, 
  Toolbar, Typography, Grid, IconButton, Tooltip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle  } from '@mui/material';
import { IFeriasColaborador } from '../../interfaces/IFeriasColaborador';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import { AuthContext } from "../../contexts/auth";
import Pagination from '../../components/common/Pagination';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

const FeriasColaboradorPage: React.FC = () => {
  const [ferias, setFerias] = useState<IFeriasColaborador[]>([]);
  const navigate = useNavigate(); 
  const { buscaFerias, enviaWhats, atualizaSenhaFerias, forcaSenha } = useContext(AuthContext);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [itensPagina, setItensPagina] = useState(10);
  const [totalPaginas, setTotalPaginas] = useState(0);
  const [totalRegistros, setTotalRegistros] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedFeriasId, setSelectedFeriasId] = useState<number | undefined>(undefined);

  const handleOpenModal = (feriasId: number | undefined) => {
    setSelectedFeriasId(feriasId);
    console.log(selectedFeriasId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedFeriasId(undefined);
  };

  const handleUpdatePassword = async () => {
    try {
      if (selectedFeriasId !== undefined) {
        await atualizaSenhaFerias(selectedFeriasId); 
        await handleSearch();
      }
      setOpenModal(false);

    } catch (error) {
      console.error('Erro ao atualizar a senha:', error);
    }
  };

  const [filters, setFilters] = useState({
    nome: '',
    email: '',
    data_inicio_ferias: null as Dayjs | null,
    data_fim_ferias: null as Dayjs | null,
  });

  const handleSearch = async () => {
    const params = {
      nome: filters.nome,
      email: filters.email,
      data_inicio_ferias: filters.data_inicio_ferias ? filters.data_inicio_ferias.format('YYYY-MM-DD') : null,
      data_fim_ferias: filters.data_fim_ferias ? filters.data_fim_ferias.format('YYYY-MM-DD') : null,
    };

    const data = await buscaFerias(params);
    setTotalPaginas(data['totalPaginas']);
    setTotalRegistros(data['totalItens']);

    const feriasData = data['itens'].map((item: { data_Inicio_Ferias: string | number | dayjs.Dayjs | Date | null | undefined; data_Fim_Ferias: string | number | dayjs.Dayjs | Date | null | undefined; data_Retorno: string | number | dayjs.Dayjs | Date | null | undefined; data_Hora_Envio_Whats: string | number | dayjs.Dayjs | Date | null | undefined; }) => ({
      ...item,
      data_Inicio_Ferias: dayjs(item.data_Inicio_Ferias),
      data_Fim_Ferias: dayjs(item.data_Fim_Ferias),
      data_Retorno: dayjs(item.data_Retorno),
      data_Hora_Envio_Whats: item.data_Hora_Envio_Whats ? dayjs(item.data_Hora_Envio_Whats) : null
  }));

    setFerias(feriasData);
  };

  const handleFilterChange = (prop: keyof typeof filters, value: any) => {
    setFilters({ ...filters, [prop]: value });
  };

  const handleNew = () => {
    navigate('/ti/ferias/cadastrar');
  };

  const handleWhats = async (feriasId: number | undefined) => {
    await enviaWhats(feriasId);
  };

  const handleForcarSenha = async (feriasId: number | undefined) => {
    await forcaSenha(feriasId);
  };

  const handleCopyPassword = (password: string) => {
    navigator.clipboard.writeText(password).then(() => {
      console.log('Senha copiada para a área de transferência');
    }).catch(err => {
      console.error('Erro ao copiar senha: ', err);
    });
  };

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6">Pesquisar Férias</Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', padding: 20 }}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Nome"
              value={filters.nome}
              onChange={(e) => handleFilterChange('nome', e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Email"
              value={filters.email}
              onChange={(e) => handleFilterChange('email', e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <DatePicker
              label="Data de Início das Férias"
              value={filters.data_inicio_ferias}
              onChange={(newDate) => handleFilterChange('data_inicio_ferias', newDate)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DatePicker
              label="Data de Fim das Férias"
              value={filters.data_fim_ferias}
              onChange={(newDate) => handleFilterChange('data_fim_ferias', newDate)}
            />
          </Grid>
          <Grid item xs={12} md={1}>
            <Button onClick={handleSearch} variant="contained" color="primary">
              Pesquisar
            </Button>
          </Grid>
          <Grid item xs={12} md={2}>
            <Button onClick={handleNew} variant="contained" color="secondary">
              Novas Férias
            </Button>
          </Grid>
        </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>ID Usuário</TableCell>
              <TableCell>Início das Férias</TableCell>
              <TableCell>Fim das Férias</TableCell>
              <TableCell>Data de Retorno</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ferias.map((ferias) => (
              <TableRow key={ferias.id}>
                <TableCell>{ferias.id}</TableCell>
                <TableCell>{ferias.usuario?.username}</TableCell>
                <TableCell>{ferias.data_Inicio_Ferias?.format('DD/MM/YYYY') }</TableCell>
                <TableCell>{ferias.data_Fim_Ferias?.format('DD/MM/YYYY')}</TableCell>
                <TableCell>{ferias.data_Retorno?.format('DD/MM/YYYY')}</TableCell>
                <TableCell>
                  <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleWhats(ferias.id)}
                      startIcon={<WhatsAppIcon />}
                      style={{ backgroundColor: '#25D366' }}>
                      Enviar Whats
                  </Button>
                  <Tooltip title="Forçar Senha nos sistemas">
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleForcarSenha(ferias.id)}
                        startIcon={<SystemUpdateAltIcon />}
                        style={{ backgroundColor: '#FF0000 ', marginLeft: '5px' }}>
                        Forçar Senha
                    </Button>
                  </Tooltip>
                  <Button
                      startIcon={<EditIcon />}
                      variant="outlined"
                      color="primary"
                      onClick={() => navigate('/ti/ferias/cadastrar', { state: { currentFerias: ferias } })}
                      style={{ marginLeft: '5px' }}
                    >
                    Editar
                  </Button>
                  <Tooltip title="Copiar Senha">
                    <IconButton 
                        color="primary" 
                        onClick={() => handleCopyPassword(ferias.senha_Alterada)} 
                        style={{ color: '#FF0000' }}
                      >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Atualizar Senha">
                    <IconButton 
                        color="primary" 
                        onClick={() => handleOpenModal(ferias.id)} 
                        style={{ color: '#0000FF' }} // Azul, mas você pode mudar para qualquer cor
                      >
                      <RefreshIcon />
                    </IconButton>
                  </Tooltip>
                  
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Pagination
                        paginaAtual={paginaAtual}
                        totalPaginas={totalPaginas}
                        PaginaAnterior={() => setPaginaAtual(paginaAtual - 1)}
                        ProximaPagina={() => setPaginaAtual(paginaAtual + 1)}
                        IrParaPagina={(pageNumber) => setPaginaAtual(pageNumber)}
                        IrParaPrimeiraPagina={() => setPaginaAtual(1)}
                        IrParaUltimaPagina={() => setPaginaAtual(totalPaginas)}
                    />
                    <Typography>Total de Itens: {totalRegistros}</Typography>
      </TableContainer>
      </LocalizationProvider>

      {/* Modal de Confirmação */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Atualizar Senha"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao atualizar a senha será atualizado nos sistemas (AD, GDocs, Workspace, SGI), você deseja realmente atualizar a senha agora?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Não
          </Button>
          <Button onClick={() => handleUpdatePassword()} color="primary" autoFocus>
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FeriasColaboradorPage;