import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Loading from '../../../components/common/Loading';
import { formatarCPFCNPJ, formatarData } from '../../../utils/formatUtils';
import Modal from '../../../components/common/Modal';
import { CDL } from '../../../interfaces/ICDL';
import '../../../components/common/Relatorio.css'


interface TableDataProps {
  ficha: CDL[];
  loading: boolean;
  msgAlert: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
}

const TableData: React.FC<TableDataProps> = ({ ficha, loading, msgAlert, modalOpen, handleCloseModal }) => {
  return (
    <div className="responsive-table-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>ID Condominio</TableCell>
            <TableCell>ID Economia</TableCell>
            <TableCell>Nosso Numero</TableCell>
            <TableCell>Cod. Pessoa</TableCell>
            <TableCell>Nome Pessoa</TableCell>
            <TableCell>Tipo Pessoa</TableCell>
            <TableCell>CPF/CNPJ</TableCell>
            <TableCell>Data Vencimento</TableCell>
            <TableCell>Data Criação</TableCell>
          </TableRow>
        </TableHead>
        {loading ? (<Loading />) : ('')}
        <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
        <TableBody>
          {ficha.map((item) => (
            <TableRow key={item?.ID}>
              <TableCell>{item.ID}</TableCell>
              <TableCell>{item.ID_Condominio}</TableCell>
              <TableCell>{item.ID_Economia}</TableCell>
              <TableCell>{item.Nosso_Numero}</TableCell>
              <TableCell>{item.Cod_Pessoa}</TableCell>
              <TableCell>{item.Nome_Pessoa}</TableCell>
              <TableCell>{item.Tipo_Pessoa === "F" ? "Física" : "Jurídica"}</TableCell>
              <TableCell>{formatarCPFCNPJ(item.CPF_CNPJ)}</TableCell>
              <TableCell>{formatarData(item.Data_Vencimento)}</TableCell>
              <TableCell>{formatarData(item.Data_Criacao)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableData;
