import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Loading from '../../../components/common/Loading';
import Modal from '../../../components/common/Modal';
import '../../../components/common/Relatorio.css'
import { INumeroDaSorte } from '../../../interfaces/ISeguros';

interface TableDataProps {
  ficha: INumeroDaSorte[];
  loading: boolean;
  msgAlert: string;
  modalOpen: boolean;
  handleCloseModal: () => void;
}

const TableData: React.FC<TableDataProps> = ({ ficha, loading, msgAlert, modalOpen, handleCloseModal }) => {
  return (
    <div className="responsive-table-container">
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>CpfCnpj</TableCell>
            <TableCell>Código</TableCell>
            <TableCell>Numero Da Sorte</TableCell>
          </TableRow>
        </TableHead>
        {loading ? <Loading /> : ''}
        <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
        <TableBody>
          {ficha.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.cpfCnpj}</TableCell>
              <TableCell>{item.codigo}</TableCell>
              <TableCell>{item.numeroDaSorte}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default TableData;
