import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = (props: any) => {
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={ true } >

                <CircularProgress color="inherit" />
                <p><h3>{props.descricao}</h3></p>
            </Backdrop>
        </>
    );
};

export default Loading;