import { Alert, Box, Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, TextField, Tooltip, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { INumeroDaSorte } from "../../../interfaces/ISeguros";
import { importarNumeroSorteSeguros } from "../../../services/api";
import { AuthContext } from "../../../contexts/auth";
import Loading from "../../../components/common/Loading";
import UploadIcon from '@mui/icons-material/Upload';

interface FilterFormNumeroDaSorteProps {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: SelectChangeEvent<HTMLSelectElement>) => void
  formData: any;
}

const FilterFormNumeroDaSorte: React.FC<FilterFormNumeroDaSorteProps> = ({ handleChange, handleSelectChange, formData }) => {
  const [loading, setLoading] = useState(false);
  const [msgAlert, setMsgAlert] = useState('');
  const [open, setOpen] = useState(false);
  const [csv, setCsv] = useState<INumeroDaSorte[]>([]);
  const [severity, setSeverity] = useState('success');
  const { importarNumerosDaSorte } = useContext(AuthContext);

  function normalizeHeader(header: string): string {
    return header.replace(/[^a-zA-Z0-9]/g, '');
  }

  useEffect(() => {
    const handleApiDispatch = async (csvData: INumeroDaSorte[]) => {
      if (csvData.length === 0) return;

      try {
        setLoading(true);
        const data = await importarNumerosDaSorte(csvData);
        
        if (data.status === 200) {
          setMsgAlert("Numeros da sorte enviados com sucesso")
        }
        setOpen(true);
      } catch (err) {
        setSeverity("error");
        setMsgAlert("Erro ao enviar numeros da sorte")
      } finally {
        setOpen(true);
        setLoading(false);
      }
    };

    handleApiDispatch(csv);
  }, [csv]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    const input = event.target;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result;
        if (typeof text === 'string') {
          const parsedCsv = parseCSV(text);
          setCsv(parsedCsv);
        }        
        input.value = '';
      };
      reader.readAsText(file);
    }
  };

  const parseCSV = (text: string): any[] => {
    const lines = text.split('\n').filter(line => line.trim() !== '');
    const headers = lines[0].split(',').map(header => normalizeHeader(header.trim()));
    const data = lines.slice(1).map(line => {
      const values = line.split(',');

      const obj: any = {};
      headers.forEach((header, index) => {
        obj[header.trim()] = values[index].trim();
      });
      return obj;
    });
    return data;
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          padding: '16px'
        }}
      >
        {loading ? <Loading /> : ''}
        <FormControl
          sx={{
            width: '30%',
            minWidth: '20%',
            marginTop: '8px',
            marginRight: '8px',
          }}
        >
          <TextField
            label="Cpf / Cnpj"
            name="CpfCnpj"
            value={formData.CpfCnpj}
            onChange={handleChange}
            variant="outlined"
          />
        </FormControl>

        <FormControl
          sx={{
            width: '30%',
            minWidth: '20%',
            marginTop: '8px',
            marginRight: '8px',
          }}
        >
          <Typography variant="subtitle1" gutterBottom>
            Importar Números da Sorte
          </Typography>
          <input
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="csvFileInput"
          />
          <label htmlFor="csvFileInput">
            <Tooltip title="ATENÇÃO! O arquivo precisa ser csv com delimitação por vírgulas, com as colunas padronizadas em codigo, cpf/cnpj e numero da sorte">
              <Button variant="contained" component="span" startIcon={<UploadIcon />}>
                Arquivo Csv
              </Button>
            </Tooltip>
          </label>
        </FormControl>

        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={severity === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
            {msgAlert}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
};


export default FilterFormNumeroDaSorte;