import React, { useState, useCallback, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Box, Button, IconButton, LinearProgress, List, ListItem, ListItemText, Typography, Paper } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';
import ImageIcon from '@mui/icons-material/Image';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description'; 
import axios from 'axios';
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

export interface FileUploadHandle {
  clearFiles: () => void;
}

interface FileUploadProps {
  onUploadComplete?: (urls: string[]) => void;
  onRemoveFile?: (fileName: string) => void;
}

const MAX_FILES = 20;

const FileUpload = forwardRef<FileUploadHandle, FileUploadProps>(({ onUploadComplete, onRemoveFile }, ref) => {
  const [files, setFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});
  const [uploadedFilesUrls, setUploadedFilesUrls] = useState<string[]>([]);
  const [dragOver, setDragOver] = useState(false);
  const listRef = useRef<HTMLUListElement>(null);
  const connection = useRef<any>(null);

  const apiUrl = "https://api.uploadhub.guarida.com.br/api/Storage";

  useImperativeHandle(ref, () => ({
    clearFiles: () => {
      setFiles([]);
      setUploadProgress({});
      setUploadedFilesUrls([]);
    }
  }));

  useEffect(() => {
    const hubUrl = `${apiUrl.replace('/api/Storage', '')}/filehub`;
    connection.current = new HubConnectionBuilder()
      .withUrl(hubUrl, {
        withCredentials: true
      })
      .build();

    connection.current.on("ReceiveMessage", (message: { status: string; fileName: string; progress?: number }) => {
      console.log(message);
      if (message.status === "ReconstitutionCompleted") {
        setUploadProgress(prevProgress => ({
          ...prevProgress,
          [message.fileName]: 90
        }));
      } else if (message.status === "Uploading") {
        setUploadProgress(prevProgress => ({
          ...prevProgress,
          [message.fileName]: message.progress!
        }));
      } else if (message.status === "Uploaded") {
        setUploadProgress(prevProgress => ({
          ...prevProgress,
          [message.fileName]: 100
        }));
        setUploadedFilesUrls(prevUrls => {
          const newUrls = [...prevUrls, `${message.fileName}`];
          if (onUploadComplete) {
            onUploadComplete(newUrls.filter(url => url !== undefined));
          }
          return newUrls;
        });
      }
    });

    const startConnection = async () => {
      if (connection.current.state === HubConnectionState.Disconnected) {
        try {
          await connection.current.start();
        } catch (error) {
          console.error('Connection failed: ', error);
          setTimeout(startConnection, 5000); // Tente reconectar após 5 segundos
        }
      }
    };

    startConnection();

    connection.current.onclose(async () => {
      await startConnection();
    });

    return () => {
      connection.current.stop();
    };
  }, [apiUrl, onUploadComplete]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [files]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFiles = Array.from(event.target.files);
      const duplicateFiles = selectedFiles.filter(file => files.some(existingFile => existingFile.name === file.name));
      
      if (duplicateFiles.length > 0) {
        alert(`Os seguintes arquivos possuem nomes duplicados: ${duplicateFiles.map(file => file.name).join(', ')}. Por favor, renomeie esses arquivos e tente novamente.`);
        return;
      }

      if (files.length + selectedFiles.length > MAX_FILES) {
        alert(`Somente é possível enviar ${MAX_FILES} arquivos por vez. Acima desta quantidade compacte todos os arquivos no formato .ZIP.`);
        return;
      }

      setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
      await uploadFiles(selectedFiles);
    }
  };

  const handleDrop = async (event: React.DragEvent) => {
    event.preventDefault();
    setDragOver(false);
    if (event.dataTransfer.files) {
      const droppedFiles = Array.from(event.dataTransfer.files);
      const duplicateFiles = droppedFiles.filter(file => files.some(existingFile => existingFile.name === file.name));
      
      if (duplicateFiles.length > 0) {
        alert(`Os seguintes arquivos possuem nomes duplicados: ${duplicateFiles.map(file => file.name).join(', ')}. Por favor, renomeie esses arquivos e tente novamente.`);
        return;
      }

      if (files.length + droppedFiles.length > MAX_FILES) {
        alert(`Somente é possível enviar ${MAX_FILES} arquivos por vez. Acima desta quantidade compacte todos os arquivos no formato .ZIP.`);
        return;
      }

      setFiles(prevFiles => [...prevFiles, ...droppedFiles]);
      await uploadFiles(droppedFiles);
    }
  };

  const uploadFiles = async (filesToUpload: File[]) => {
    const newUploadedUrls: string[] = [];
    for (const file of filesToUpload) {
      const uploadedUrl = await uploadFileInChunks(file);
      if (uploadedUrl) {
        newUploadedUrls.push(uploadedUrl);
      }
    }
    if (onUploadComplete) {
      onUploadComplete(newUploadedUrls);
    }
  };

  const uploadFileInChunks = async (file: File): Promise<string | undefined> => {
    const chunkSize = 10 * 1024 * 1024; // 10 MB
    const totalChunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    let cumulativeProgress = 0;

    const uploadNextChunk = async (): Promise<string | undefined> => {
      const start = currentChunk * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);

      const formData = new FormData();
      formData.append('chunk', chunk);
      formData.append('chunkNumber', (currentChunk + 1).toString());
      formData.append('totalChunks', totalChunks.toString());
      formData.append('fileName', file.name);

      try {
        await axios.post(`${apiUrl}/uploadChunk`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: (progressEvent) => {
            if (progressEvent.total) {
              const progress = (progressEvent.loaded / progressEvent.total) * 100;
              cumulativeProgress = ((currentChunk + progress / 100) / totalChunks) * 100;
              setUploadProgress(prevProgress => ({
                ...prevProgress,
                [file.name]: Math.round(cumulativeProgress * 0.9)
              }));
            }
          }
        });

        currentChunk++;

        if (currentChunk < totalChunks) {
          return await uploadNextChunk();
        } else {
          return `${file.name}`;
        }
      } catch (error) {
        console.error('Error uploading chunk:', error);
        return undefined;
      }
    };

    return await uploadNextChunk();
  };

  const handleRemoveFile = (index: number) => {
    const fileToRemove = files[index];
    setFiles(files.filter((_, i) => i !== index));
    setUploadProgress(prevProgress => {
      const newProgress = { ...prevProgress };
      delete newProgress[fileToRemove.name];
      return newProgress;
    });

    setUploadedFilesUrls(prevUrls => {
      const newUrls = prevUrls.filter(url => url !== `${fileToRemove.name}`);
      if (onRemoveFile) {
        onRemoveFile(fileToRemove.name);
      }
      return newUrls;
    });
  };

  const formatFileSize = (size: number) => {
    if (size > 1024 * 1024) {
        return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    }
    return `${(size / 1024).toFixed(2)} KB`;
  };

  const handleDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    setDragOver(true);
  }, []);

  const handleDragLeave = useCallback(() => {
    setDragOver(false);
  }, []);

  const getFileIcon = (fileName: string) => {
    const extension = fileName.split('.').pop();
    switch (extension) {
      case 'pdf':
        return <PictureAsPdfIcon style={{ color: 'red' }} />;
      case 'doc':
      case 'docx':
        return <ArticleIcon style={{ color: 'blue' }} />;
      case 'png':
      case 'jpg':
      case 'jpeg':
        return <ImageIcon style={{ color: 'blue' }} />;
      case 'xls':
      case 'xlsx':
        return <DescriptionIcon style={{ color: 'green' }} />;
      case 'zip':
        return <FolderZipIcon style={{ color: 'gray' }} />;
      default:
        return <DescriptionIcon />;
    }
  };

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" height="300px" bgcolor="#f5f5f5" sx={{ maxWidth: 1200 }}>
      <Paper elevation={3} sx={{ p: 4, display: 'flex', width: '100%', height: '100%', maxWidth: 1400 }}>
        <Box
          flex="1"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          border="2px dashed grey"
          p={4}
          borderRadius="8px"
          mr={2}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          sx={{ backgroundColor: dragOver ? '#e0e0e0' : 'inherit' }}
        >
          <CloudUploadIcon fontSize="large" color="primary" />
          <Typography variant="h6" color="textSecondary">Arraste e Solte aqui ou</Typography>
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Selecione
            <input type="file" hidden multiple onChange={handleFileChange} />
          </Button>
        </Box>
        <Box flex="1" maxHeight="250px" overflow="auto" >
          <List sx={{ width: '100%', fontSize: '0.875rem' }} ref={listRef}>
            {files.map((file, index) => (
              <ListItem key={index} sx={{ mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Box display="flex" alignItems="center" width="100%">
                  {getFileIcon(file.name)}
                  <ListItemText primary={file.name} secondary={formatFileSize(file.size)} sx={{ ml: 2, fontSize: '0.75rem' }} />
                </Box>
                <Box sx={{ width: '100%', mt: 1 }}>
                  <LinearProgress variant="determinate" value={uploadProgress[file.name] || 0} />
                </Box>
                <IconButton edge="end" aria-label="delete" onClick={() => handleRemoveFile(index)} sx={{ alignSelf: 'flex-end' }}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Paper>
    </Box>
  );
});

export default FileUpload;
