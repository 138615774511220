export function formatarCPFCNPJ(cpfCnpj: string | undefined): string {
    if (cpfCnpj === undefined) {
        return "";
    }

    // Verifique o tamanho da string e aplique a formatação correta
    if (cpfCnpj.length === 11) {
        return cpfCnpj.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4'); // Formatação para CPF
    } else if (cpfCnpj.length === 14) {
        return cpfCnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5'); // Formatação para CNPJ
    } else {
        return cpfCnpj; // Retorna o valor original se não for CPF nem CNPJ válido
    }
}


export function formatarData(dataString: string | undefined): string {
    if (dataString === undefined || dataString === null) return "";
    
    const minhaData = new Date(dataString);

    const dia = String(minhaData.getDate()).padStart(2, '0');
    const mes = String(minhaData.getMonth() + 1).padStart(2, '0');
    const ano = minhaData.getFullYear();
    const horas = String(minhaData.getHours()).padStart(2, '0');
    const minutos = String(minhaData.getMinutes()).padStart(2, '0');
    const segundos = String(minhaData.getSeconds()).padStart(2, '0');

    const time = `${horas}:${minutos}:${segundos}`;

    const dataFormatada = `${dia}/${mes}/${ano} ` + ((time !== '00:00:00') ? `- ${time}` : "");
    return dataFormatada;
}