import React, { useState, useContext, Dispatch, SetStateAction, useEffect } from 'react';
import '../../../components/common/modal.css';
import { Autocomplete, Button, CircularProgress, Snackbar, TextField } from '@mui/material';
import { AuthContext } from "../../../contexts/auth";
import { IBloqueioEmail } from '../interfaces/IBloqueioEmail';

interface ModalProps {
    ficha: IBloqueioEmail[];
    setFicha(ficha: any): void;
    isOpen: boolean;
    onClose: () => void;
    msgAlert: string;
    setOpen: Dispatch<SetStateAction<boolean>>;
    setSeverity: Dispatch<SetStateAction<'success' | 'error'>>;
    setMsg: Dispatch<SetStateAction<string>>;
}

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

const Modal: React.FC<ModalProps> = ({ ficha, setFicha, isOpen, onClose, msgAlert, setOpen, setSeverity, setMsg  }) => {
    const [email, setEmail] = useState('');
    const [tipo, setTipo] = useState('');
    const [tipoOptions, setTipoOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const { criaBloqueadorEmail } = useContext(AuthContext);
    const { buscaTiposEmail } = useContext(AuthContext);

    const handleAdd = async () => {
        try {
            const response = await criaBloqueadorEmail(email, tipo);
            if (response == 200) {
                const novoRegistro: IBloqueioEmail = {
                    ClientEmail: email,
                    EmailType: tipo,
                    }
                const novaFicha = [...ficha, novoRegistro];
                setFicha(novaFicha);
                setMsg("Email adicionado com sucesso");
                setSeverity("success");
                setOpen(true);
            }
            setEmail("");
            setTipo("");
            onClose();
        } catch (error) {
            onClose();
            setMsg("Erro ao adicionar email");
            setSeverity("error");
            setOpen(true);
            setEmail("");
            setTipo("");
        }
    }
    
    const handleFetchEmailTypes = async () => {
        setLoading(true);
            try {
                const response = await buscaTiposEmail();
                setTipoOptions(response);
            } catch (error) {
                onClose();
                setMsg("Erro ao recuperar tipo de email");
                setSeverity("error");
                setOpen(true);
            } finally {
                setLoading(false);
            }
    };
    if (!isOpen) return null;
    return (
            <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-body">
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        fullWidth
                        margin="normal"
                        type="email"
                        error={email !== "" && !emailRegex.test(email)}
                        helperText={email !== "" && !emailRegex.test(email) ? "Endereço de email inválido" : ""}
                        required
                    />
                    <Autocomplete
                        value={tipo}
                        onOpen={handleFetchEmailTypes}
                        onChange={(event, newValue) => setTipo(newValue || '')}
                        options={tipoOptions}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Tipo"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress color="inherit" size={24} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                                required
                            />
                        )}
                    />
                </div>
                <div className="modal-footer">
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mr: 1 }}
                        onClick={handleAdd}
                    >
                        Adicionar
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
