import React, { useState, useContext, useRef, useEffect } from 'react';
import { Paper, Button, TextField, Grid, Typography, Select, MenuItem, List, ListItem, ListItemText, 
    createFilterOptions, Autocomplete, IconButton,Tooltip  } from '@mui/material';
import { styled } from '@mui/material/styles';
import Modal from '../../components/common/Modal2';
import Loading from '../../components/common/Loading';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import { AuthContext } from "../../contexts/auth";
import { BoletosInadimplenciaNS } from '../../interfaces/IBoletosInadimplenciaNS';
import Pagination from '../../components/common/Pagination';
import { subDays } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { OpenInNew as OpenInNewIcon, FileCopy as FileCopyIcon } from '@mui/icons-material';

const PrimaryText = styled(Typography)(({ theme }) => ({
    fontWeight: 'bold',
}));

const SecondaryText = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const optionsTipo = [
    { value: '-1', label: 'Todos Tipos' },    
    { value: '0', label: 'Condominio' },
    { value: '1', label: 'Aluguel' }
];

dayjs.extend(utc);
dayjs.extend(timezone);

function formatarDataHora(dataHora: string | Date | null) {
    if (dataHora === null) {
        return '';
    }
    const formatoDataHora = 'DD/MM/YYYY HH:mm:ss';
    return dayjs(dataHora).tz('America/Sao_Paulo').format(formatoDataHora);
}

function formatarData(dataHora: string | Date | null) {
    if (dataHora === null) {
        return '';
    }
    const formatoDataHora = 'DD/MM/YYYY';
    return dayjs(dataHora).tz('America/Sao_Paulo').format(formatoDataHora);
}

interface EconomiaItem {
    Id_Economia: number;
    Descricao: string;
    Bloco: string;
}

interface InquilinoItem {
    ID_Imovel: number;
    Nome_Pessoa: string;
}

interface AutocompleteOption {
    label: string;
    id: string;
}

const BoletoInadimplenciaNSPage: React.FC = () => {
    const currentDate = new Date();

    const [loading, setLoading] = useState(false);
    const campoCod = useRef<HTMLInputElement>(null);
    const [dados, setDados] = useState<BoletosInadimplenciaNS[]>([]);

    const [modalOpen, setModalOpen] = useState(false);
    const [msgAlert, setMsgAlert] = useState("");
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [itensPagina, setItensPagina] = useState(10);
    const [totalPaginas, setTotalPaginas] = useState(0);
    const [totalRegistros, setTotalRegistros] = useState(0);
    const { filtraBoletoInadimplencia, exportaBoletoInadimplencia, economiasByCondominio, getAllInquilinos } = useContext(AuthContext);
    const [condominio, setCondominio] = useState('');
    const [imovel, setImovel] = useState('');
    const [email, setEmail] = useState('');
    const [dataInicio, setDataInicio] = React.useState<Dayjs | null>(dayjs().subtract(14, 'day'));
    const [dataFim, setDataFim] = React.useState<Dayjs | null>(dayjs());
    const [selectedOptionsTipo, setselectedOptionsTipo] = useState('-1');
    const [selectedOptionsPrazoBoleto, setSelectedOptionsPrazoBoleto] = useState('0');
    const [selectedOptionsStatusEnvio, setSelectedOptionsStatusEnvio] = useState('0');
    const [selectedOptionsEconomia, setselectedOptionsEconomia] = useState<string | null>(null);
    const [economias, setEconomias] = useState<AutocompleteOption[]>([]);
    const [selectedOptionsInquilino, setselectedOptionsInquilino] = useState<string | null>(null);
    const [inquilinos, setInquilinos] = useState<AutocompleteOption[]>([]);

    
    const getInquilinos = async () => {
        try {
            const response = await getAllInquilinos();
 
            const _inquilinos: InquilinoItem[] = response;
            const autocompleteOptions: AutocompleteOption[] = _inquilinos.map((inquilino) => ({
                label: inquilino.Nome_Pessoa,
                id: inquilino.ID_Imovel.toString(),
                }));

            setInquilinos(autocompleteOptions);
        } catch (error) {
          console.error('Erro ao buscar dados da API Inquilinos:', error);
        }
    };

    useEffect(() => {
        getInquilinos();
        getBoletos();
    }, [paginaAtual, itensPagina]);

    const handleSelectChangeTipo = (event: any) => {
        setselectedOptionsTipo(event.target.value);
    };

    const handleSelectChangePrazoBoleto = (event: any) => {
        setSelectedOptionsPrazoBoleto(event.target.value);
    };

    const handleSelectChangeStatusEnvio = (event: any) => {
        setSelectedOptionsStatusEnvio(event.target.value);
    };

    const handleSelectChangeEconomia = (event: any, newValue: any) => {
        if (newValue){
            setselectedOptionsEconomia(newValue.id);
        } else{
            setselectedOptionsEconomia(null);
        }
    };

    const handleSelectChangeInquilino = (event: any, newValue: any) => {
        
        if (newValue){
            setselectedOptionsInquilino(newValue.id);
            setImovel(newValue.id);
        } else{
            setselectedOptionsInquilino(null);
            setImovel("");
        }
    };

    const handleBlurCondominio = React.useCallback((e: HTMLInputElement) => {
        if (e.value.trim() !== ''){
            getEconomias(e.value);
        } else{
            setEconomias([]);
        }
    }, []);

    const getEconomias = async (codigo: string) => {
        try {
            let retorno = await economiasByCondominio(codigo);
            const economias: EconomiaItem[] = retorno;
            const autocompleteOptions: AutocompleteOption[] = economias.map((economia) => ({
                label: economia.Bloco + "-" + economia.Descricao,
                id: economia.Id_Economia.toString(),
              }));
    
              setEconomias(autocompleteOptions);
        } catch (err: any) {
            console.log(err.response.data);
            //setSeverity("error");
            //setMsgAlert(err.response.data['mensagem']);
        }
    };

    const handleButtonExportarClick = async () => {
        const queryParams = {
            "ID_Condominio": condominio.trim() === '' ? null : condominio,
            "Data_Inicio": dataInicio,
            "Data_Fim": dataFim,
            "Tipo_Boleto": selectedOptionsTipo.trim() === '-1' ? null : selectedOptionsTipo,
            "ID_Economia": selectedOptionsEconomia,
            "ID_Imovel": imovel.trim() === '' ? null : imovel,
            "Email": email.trim() === '' ? null : email,
            "BoletoDias": selectedOptionsPrazoBoleto.trim() === '0' ? null : selectedOptionsPrazoBoleto,
            "StatusEnvio": selectedOptionsStatusEnvio
        };

        var retorno = await exportaBoletoInadimplencia(queryParams);
        const data = new Blob([retorno], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        downloadBlob(data, 'dados_Boletos_ns.xlsx');
    };

    function downloadBlob(blob: Blob, fileName: string) {
        // Criar um URL para o Blob
        const blobUrl = window.URL.createObjectURL(blob);
    
        // Criar um link temporário
        const link = document.createElement('a');
    
        // Definir o URL do link para o URL do Blob
        link.href = blobUrl;
    
        // Definir o atributo de download com o nome do arquivo
        link.download = fileName;
    
        // Adicionar o link ao corpo do documento
        document.body.appendChild(link);
    
        // Simular um clique no link para iniciar o download
        link.click();
    
        // Remover o link do corpo do documento
        document.body.removeChild(link);
    
        // Liberar o URL do Blob
        window.URL.revokeObjectURL(blobUrl);
    }

    const getBoletos = async () => {
        setLoading(true);
        const queryParams = {
            "ID_Condominio": condominio.trim() === '' ? null : condominio,
            "Data_Inicio": dataInicio,
            "Data_Fim": dataFim,
            "Tipo_Boleto": selectedOptionsTipo.trim() === '-1' ? null : selectedOptionsTipo,
            "ID_Economia": selectedOptionsEconomia,
            "ID_Imovel": imovel.trim() === '' ? null : imovel,
            "Email": email.trim() === '' ? null : email,
            "BoletoDias": selectedOptionsPrazoBoleto.trim() === '0' ? null : selectedOptionsPrazoBoleto,
            "StatusEnvio": selectedOptionsStatusEnvio,
            "PageNumber": paginaAtual
        };

        var retorno = await filtraBoletoInadimplencia(queryParams);
        setLoading(false);
        setTotalPaginas(retorno['totalPaginas']);
        setTotalRegistros(retorno['totalItens']);
        setDados(retorno['itens']);
    }

    const handleButtonClick = async () => {
        await getBoletos();
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    const handleOpenInNewTab = (link : string | undefined) => {
        window.open(link, '_blank');
    };

    const handleCopyToClipboard = (link : string | undefined) => {
        if (link) {
            navigator.clipboard.writeText(link);
        }
    };

    const filterOptions = createFilterOptions({
        ignoreCase: true,
        matchFrom: "start",
        limit: 60,
      });

    return (
        <>  
        <Modal isOpen={modalOpen} onClose={handleCloseModal} text={msgAlert} />
        <Grid container spacing={1}>
            <Grid item xs={12} sm={2}>
                <Select label="Tipo Boleto" fullWidth value={selectedOptionsTipo} onChange={handleSelectChangeTipo} >
                    {optionsTipo.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
            <Grid item xs={12} sm={2}>
                <Select label="Prazo Boleto" fullWidth value={selectedOptionsPrazoBoleto} onChange={handleSelectChangePrazoBoleto} >
                    <MenuItem key="0" value="0">Todos</MenuItem>
                    <MenuItem key="31" value="31">31 dias</MenuItem>
                    <MenuItem key="60" value="60">60 dias</MenuItem>
                </Select>
            </Grid>

            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                <Grid item xs={12} sm={3}>
                    <DatePicker 
                        label="Data de Início"
                        defaultValue={dayjs().subtract(14, 'day')}
                        onChange={(date) => setDataInicio(date)}
                        value={dataInicio}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                <DatePicker 
                        label="Data Final"
                        defaultValue={dayjs()}
                        onChange={(date) => setDataFim(date)}
                        value={dataFim}
                    />
                </Grid>
            </LocalizationProvider>

            <Grid item xs={12} sm={2}>
                <Select label="Status Envio" fullWidth value={selectedOptionsStatusEnvio} onChange={handleSelectChangeStatusEnvio} >
                    <MenuItem key="0" value="0">Todos</MenuItem>
                    <MenuItem key="1" value="1">Somente Enviados</MenuItem>
                    <MenuItem key="2" value="2">Somente NÃO Enviados</MenuItem>
                </Select>
            </Grid>
            <Grid item xs={12} sm={2}>
                <TextField
                    label="Código Condomínio"
                    value={condominio}
                    onChange={(e) => setCondominio(e.target.value)}
                    onBlur={(e) => handleBlurCondominio(e.target as HTMLInputElement)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Autocomplete
                    disablePortal
                    id="combo-box-economia"
                    options={economias}
                    value={economias.find((option) => option.id === selectedOptionsEconomia) || null}
                    onChange={handleSelectChangeEconomia}
                    renderInput={(params) => <TextField {...params} label="Economia" variant="outlined"/>}
                />
            </Grid>
                
            <Grid item xs={12} sm={3}>
                <TextField
                    label="Código Imóvel"
                    value={imovel}
                    onChange={(e) => setImovel(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={7}>
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    fullWidth
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Autocomplete
                        disablePortal
                        id="combo-box-inquilino"
                        options={inquilinos}
                        value={inquilinos.find((option) => option.id === selectedOptionsInquilino) || null}
                        onChange={handleSelectChangeInquilino}
                        renderInput={(params) => <TextField {...params} label="Inquilino" variant="outlined"/>}
                        filterOptions={filterOptions}
                    />
            </Grid>
            
            <Grid item xs={12} sm={6}>
                <Button variant="contained" color="primary" onClick={handleButtonClick}>
                    Pesquisar
                </Button> &nbsp;
                <Button variant="contained" color="primary" onClick={handleButtonExportarClick}>
                    Exportar
                </Button> 
            
            </Grid>
        </Grid>
            
        {loading ? (
            <Loading descricao="Buscando as informa&ccedil;&otilde;es"/>
        ) : (
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 600, "margin-top": "1%" }} size="small" aria-label="a dense table" >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell >Condomínio</StyledTableCell>    
                            <StyledTableCell >Prazo</StyledTableCell>                       
                            <StyledTableCell >Economia</StyledTableCell>
                            <StyledTableCell >Vencimento Original</StyledTableCell>   
                            <StyledTableCell >Link</StyledTableCell>
                            <StyledTableCell >Data Vencimento</StyledTableCell>    
                            <StyledTableCell >Email</StyledTableCell>                      
                            <StyledTableCell >LOG</StyledTableCell>                         
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {dados && dados.length > 0 ? (
                        dados.map((cur, index) =>
                            <StyledTableRow key={index}>                                
                                <StyledTableCell>{cur.iD_Condominio}</StyledTableCell>
                                <StyledTableCell>{cur.boletoDias}</StyledTableCell>
                                <StyledTableCell>{cur.notificacoes && cur.notificacoes.length > 0 && cur.notificacoes[0].economiaCondominio ? (
                                                cur.notificacoes[0].economiaCondominio.bloco + '-' + cur.notificacoes[0].economiaCondominio.descricao):('')}</StyledTableCell>
                                <StyledTableCell>{formatarData(cur.dataVencimentoOriginal)}</StyledTableCell>
                                <StyledTableCell>
                                    <Tooltip title="Abrir em Nova Aba">
                                        <IconButton onClick={() => handleOpenInNewTab(cur.link_PDF_Boleto)} >
                                            <OpenInNewIcon />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Copiar para Área de Transferência">
                                        <IconButton onClick={() => handleCopyToClipboard(cur.link_PDF_Boleto)}>
                                            <FileCopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                </StyledTableCell>
                                <StyledTableCell>{formatarData(cur.dataVencimento)}</StyledTableCell>
                                <StyledTableCell>{cur.email_Pessoa}</StyledTableCell>
                                <StyledTableCell>
                                    <ListItemText                                    
                                        secondary={
                                            cur.notificacoes && cur.notificacoes.length > 0 && cur.notificacoes[0].logsNotificacao ? (
                                                cur.notificacoes[0].logsNotificacao.map(log => (
                                                    <div key={log.iD_LogNotificacao}>
                                                        {log.tipo_Log === 0 && (
                                                            log.jsoN_Erro!= null && log.jsoN_Erro !== '' ? 
                                                                (
                                                                    <>
                                                                        <PrimaryText variant="subtitle1" color="error">Erro ao Enviar Email</PrimaryText>
                                                                        <Typography variant="body2">{log.jsoN_Erro}</Typography>
                                                                    </>
                                                                ) : 
                                                                (
                                                                    <>
                                                                        <PrimaryText variant="subtitle1">Email</PrimaryText>
                                                                        <Typography variant="body2">Data/Hora de Envio: {formatarDataHora(log.datahora_Envio)}</Typography>
                                                                        <Typography variant="body2">Data/Hora de Entregue: {formatarDataHora(log.datahora_Entregue)}</Typography>
                                                                        <Typography variant="body2">Data/Hora de Abertura:{formatarDataHora(log.datahora_Abertura)}</Typography>
                                                                        <Typography variant="body2">Data/Hora de Click: {formatarDataHora(log.datahora_Clicou)}</Typography>
                                                                    </>
                                                                )
                                                            
                                                        )}                                                        
                                                    </div>
                                                ))
                                            ) : (
                                                <Typography variant="body2">Sem logs de notificação disponíveis</Typography>
                                            )
                                        }
                                    />
                                </StyledTableCell>
                            </StyledTableRow>
                        )) : (
                            <TableRow>
                                <TableCell colSpan={7}>Nenhum dado disponível.</TableCell>
                            </TableRow>
                        )}                        
                    </TableBody>                    
                </Table>
                <Pagination
                        paginaAtual={paginaAtual}
                        totalPaginas={totalPaginas}
                        PaginaAnterior={() => setPaginaAtual(paginaAtual - 1)}
                        ProximaPagina={() => setPaginaAtual(paginaAtual + 1)}
                        IrParaPagina={(pageNumber) => setPaginaAtual(pageNumber)}
                        IrParaPrimeiraPagina={() => setPaginaAtual(1)}
                        IrParaUltimaPagina={() => setPaginaAtual(totalPaginas)}
                    />
                <Typography>Total de Itens: {totalRegistros}</Typography>                
            </TableContainer >
        )}
        </>
    );
    
};

export default BoletoInadimplenciaNSPage;