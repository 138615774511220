import { FormControl, SelectChangeEvent, TextField } from "@mui/material";

interface FilterFormIntegradorProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelectChange: (event: SelectChangeEvent<HTMLSelectElement>) => void
    formData: any;
}

const FilterFormBuscaClientes: React.FC<FilterFormIntegradorProps> = ({ handleChange, formData }) => {
    return (
        <>
             <FormControl
                sx={{
                    width: '15%',
                    minWidth: '15%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="Código Pessoa"
                    name="Codigo_pessoa"
                    value={formData.Codigo_pessoa}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>

            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '25%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="Nome"
                    name="Nome"
                    value={formData.Nome}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>
            
            <FormControl
                sx={{
                    width: '25%',
                    minWidth: '25%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="Condomínio"
                    name="Condominio"
                    value={formData.Condominio}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>

            <FormControl
                sx={{
                    width: '15%',
                    minWidth: '15%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="Economia"
                    name="Economia"
                    value={formData.Economia}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>

            <FormControl
                sx={{
                    width: '15%',
                    minWidth: '15%',
                    marginTop: '8px',
                    marginRight: '8px',

                }}>
                <TextField
                    label="CpfCnpj"
                    name="Cpfcnpj"
                    value={formData.Cpfcnpj}
                    onChange={handleChange}
                    variant="outlined"
                />
            </FormControl>
        </>
    );
};

export default FilterFormBuscaClientes;