import { Box, FormControl, FormHelperText, Grid, TextField } from "@mui/material";

interface IFilterFormOnboardingProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleChangeDate: (event: React.ChangeEvent<HTMLInputElement>) => void;
    formData: any;
}

const FilterFormOnboarding: React.FC<IFilterFormOnboardingProps> = ({ handleChange, handleChangeDate, formData }) => {
    const defaultDataDe = formData.dataInicial ?
        (formData.dataInicial.toISOString().substring(0, 10) === '0001-01-01' ?
            "" : formData.dataInicial.toISOString().substring(0, 10)
        ) : "";

    const defaultDataAte = formData.dataFinal ?
        (formData.dataFinal.toISOString().substring(0, 10) === '0001-01-01' ?
            "" : formData.dataFinal.toISOString().substring(0, 10)
        ) : "";

    const campoObrigatorio =
        (formData.dataInicial && formData.dataFinal && formData.dataFinal.toISOString().substring(0, 10) !== '0001-01-01') ||
        (!formData.dataInicial && formData.dataFinal) ||
        (formData.dataInicial && formData.dataInicial.toISOString().substring(0, 10) !== '0001-01-01' && !formData.dataFinal);

    const defaultEmail = formData.ClientEmail ? formData.ClientEmail : "";
    const defaultCodigoImovel = formData.CodImovel ? formData.CodImovel : "";
    const defaultCodPessoa = formData.CodPessoa ? formData.CodPessoa : "";
    const defaultGestor = formData.ManagerName ? formData.ManagerName : "";
    const defaultTemplate = formData.TemplateName ? formData.TemplateName : "";
    const defaultTipo = formData.TemplateType ? formData.TemplateType : "";

    return (
        <Grid container spacing={2}>
            {/* Seção esquerda: 3 colunas com 3 filtros em cima e 3 embaixo */}
            <Grid item xs={9} key="filters-section">
                <Grid container spacing={2}>
                    {/* Linha superior com 3 filtros */}
                    <Grid item xs={4} key="email-filter">
                        
                        <FormControl sx={{ width: '100%' }}>
                            <FormHelperText>Busca por email:</FormHelperText>
                            <TextField
                                label="Email"
                                type="text"
                                name="ClientEmail"
                                value={defaultEmail}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} key="codigo-imovel-filter">
                        <FormControl sx={{ width: '100%' }}>
                            <FormHelperText>Busca por Código imóvel:</FormHelperText>
                            <TextField
                                label="Código imóvel"
                                type="text"
                                name="CodImovel"
                                value={defaultCodigoImovel}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} key="cod-pessoa-filter">
                        <FormControl sx={{ width: '100%' }}>
                            <FormHelperText>Busca por Código Pessoa:</FormHelperText>
                            <TextField
                                label="CodPessoa"
                                type="text"
                                name="CodPessoa"
                                value={defaultCodPessoa}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>

                    {/* Linha inferior com 3 filtros */}
                    <Grid item xs={4} key="gestor-filter">
                        <FormControl sx={{ width: '100%' }}>
                            <FormHelperText>Busca por Nome Gestor:</FormHelperText>
                            <TextField
                                label="Gestor"
                                type="text"
                                name="ManagerName"
                                value={defaultGestor}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} key="template-filter">
                        <FormControl sx={{ width: '100%' }}>
                            <FormHelperText>Busca por Template:</FormHelperText>
                            <TextField
                                label="Template"
                                type="text"
                                name="TemplateName"
                                value={defaultTemplate}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} key="tipo-filter">
                        <FormControl sx={{ width: '100%' }}>
                            <FormHelperText>Busca por Tipo:</FormHelperText>
                            <TextField
                                label="Tipo"
                                type="text"
                                name="TemplateType"
                                value={defaultTipo}
                                onChange={handleChange}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            {/* Seção direita: Período (datas) */}
            <Grid item xs={3} key="dates-section">
                <Grid container spacing={2}>
                    <Grid item xs={12} key="data-inicial-filter">
                        <FormControl sx={{ width: '100%' }}>
                            <FormHelperText>Busca por período (De):</FormHelperText>
                            <TextField
                                label="De:"
                                type="date"
                                name="dataInicial"
                                value={defaultDataDe}
                                onChange={handleChangeDate}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                required={campoObrigatorio}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} key="data-final-filter">
                        <FormControl sx={{ width: '100%' }}>
                            <FormHelperText>Busca por período (Até):</FormHelperText>
                            <TextField
                                label="Até:"
                                type="date"
                                name="dataFinal"
                                value={defaultDataAte}
                                onChange={handleChangeDate}
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                required={campoObrigatorio}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default FilterFormOnboarding;
