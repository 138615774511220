import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useState, useContext, } from "react";
import Relatorio from "../../../components/common/Relatorio";
import { AuthContext } from "../../../contexts/auth";
import { IOnboardingRelatorio } from "../interfaces/IOnboarding";
import "../../../styles/styles.css";

function RelatorioEmailsOnboarding() {
const {buscaRelatorioEmailOnboarding, exportaRelatorioEmailOnboarding } = useContext(AuthContext);
const [ficha, setFicha] = useState<IOnboardingRelatorio[]>([]);

    return (
        <>
            <Typography variant="h6">Relatório de Emails de Onboarding</Typography>
            <Relatorio
                busca={buscaRelatorioEmailOnboarding}
                exporta={exportaRelatorioEmailOnboarding}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"EmailOnboarding"}
            />
            <Divider variant="middle" />
        </>
    );
}

export default RelatorioEmailsOnboarding;
