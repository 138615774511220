import React, { useState, useContext } from 'react';
import { Typography } from '@mui/material';
import { AuthContext } from '../../../contexts/auth';
import { BuscaClientes } from '../../../interfaces/IBuscaClientes';
import BuscarClientes from '../../../components/common/BuscaClientes';

const BuscarClientesPage: React.FC = () => {
    const [ficha, setFicha] = useState<BuscaClientes[]>([]);
    const { buscaClientes} = useContext(AuthContext);
    const { atualizaInadimplente} = useContext(AuthContext);
    const { exportaListaBuscaClientes } = useContext(AuthContext);

    return (
        <div>
            <Typography variant="h6">Buscar Clientes</Typography>
            <BuscarClientes
                busca={buscaClientes}
                exporta={exportaListaBuscaClientes}
                atualiza={atualizaInadimplente}
                ficha={ficha}
                setFicha={setFicha}
            />
        </div>
    );
};

export default BuscarClientesPage;