import React, { useState } from 'react';
import { AlertProps, Snackbar, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import { BuscaIntegracao } from '../../../interfaces/IBuscaIntegracoesBetalabs';
import { formatarData } from '../../../utils/formatUtils';
import Loading from '../../../components/common/Loading';
import '../../../components/common/Relatorio.css'
interface TableDataProps {
  ficha: BuscaIntegracao[];
  loading: boolean;
  modalOpen: boolean;
  handleCloseModal: () => void;
}
interface CheckboxState {
  [key: string]: {
    Ativo: boolean;
    Inadimplente: boolean;
  };
}
const TableDataIntegracao: React.FC<TableDataProps> = ({ ficha, loading }) => {
  const [severity, setSeverity] = useState('success');
  const [msgAlert, setMsgAlert] = useState('');
  const [loadingModal, setLoadingModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState<CheckboxState>({});


  const Alert = React.forwardRef<HTMLDivElement, AlertProps>((props, ref) => {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity === 'success' ? 'success' : 'error'} sx={{ width: '100%' }}>
          {msgAlert}
        </Alert>
      </Snackbar>
      <div className="responsive-table-container">
        <Table>
          <TableHead>
            <TableRow>
            <TableCell>ID</TableCell>
              <TableCell>Código condomínio</TableCell>
              <TableCell>Nome condomínio</TableCell>
              <TableCell>Integração</TableCell>
              <TableCell>Usuários cadastrados</TableCell>
              <TableCell>Usuários com erro</TableCell>
              <TableCell>Data de integração</TableCell>
              <TableCell>User Gdocs</TableCell>
            </TableRow>
          </TableHead>
          {loading ? <Loading /> : ''}
          {loadingModal ? <Loading /> : ''}
          <TableBody>
            {ficha.map((item) => (
              <TableRow key={item?.Id}>
                <TableCell>{item.Id}</TableCell>
                <TableCell>{item.Codigo_condominio}</TableCell>
                <TableCell>{item.Nome_condominio}</TableCell>
                <TableCell>{item.Numero_integracao}</TableCell>
                <TableCell>{item.Usuarios_cadastrados}</TableCell>
                <TableCell>{item.Usuarios_com_erro}</TableCell>
                <TableCell>{formatarData(item.Data_integracao)}</TableCell>
                <TableCell>{item.User_Gdocs}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </>
  );
};
export default TableDataIntegracao;