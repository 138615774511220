import { Autocomplete, Box, CircularProgress, FormControl, FormHelperText, Grid, SelectChangeEvent, TextField } from "@mui/material";
import { AuthContext } from "../../../contexts/auth";
import { useContext, useState } from "react";

interface IFilterBloqueioEmailProps {
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    formData: any;
}

const FilterFormBloqueioEmail: React.FC<IFilterBloqueioEmailProps> = ({ handleChange, formData }) => {
    const defaultEmail = formData.ClientEmail ? formData.ClientEmail : "";
    const defaultTipo = formData.EmailType ? formData.EmailType : "";
    const { buscaTiposEmail } = useContext(AuthContext);
    const [tipoOptions, setTipoOptions] = useState<string[]>([]);
    const [loading, setLoading] = useState(false);
    const [tipo, setTipo] = useState<string>(formData.EmailType || "");

    const handleFetchEmailTypes = async () => {
        const response = await buscaTiposEmail();
        setTipoOptions(response);
    }

    const handleSetEmailType = (tipo: string) => {
        setTipo(tipo);
    };
    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                    <FormControl
                        sx={{
                            width: '100%',
                            minWidth: '100%',
                            marginTop: '8px',
                            marginRight: '8px',
                        }}>
                        <FormHelperText sx={{ mb: 1 }}>Busca por email:</FormHelperText>

                        <TextField
                            sx={{ mb: 2, margin: 0, padding: 0}}
                            label="Email"
                            type="text"
                            name="ClientEmail"
                            value={defaultEmail}
                            onChange={handleChange}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                            required={false}
                        />
                    </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
                    <FormControl
                        sx={{
                            width: '100%',
                            minWidth: '100%',
                            marginTop: '8px',
                            marginLeft: '8px',
                        }}>
                        <FormHelperText sx={{ mb: 1 }}>Busca por tipo:</FormHelperText>
                        <Autocomplete
                        value={defaultTipo}
                        onOpen={handleFetchEmailTypes}
                        onChange={(event, newValue) => {
                            setTipo(newValue || '');
                            if (event) {
                                handleChange({
                                    target: { name: 'EmailType', value: newValue || '' },
                                } as React.ChangeEvent<HTMLInputElement>);
                            }
                        }}
                        options={tipoOptions}
                        loading={loading}
                        renderInput={(params) => (
                            <TextField
                            sx={{ mb: 2, margin: 0, padding: 0}}
                                {...params}
                                label="Tipo"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="EmailType"
                                value={defaultTipo}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {loading ? <CircularProgress color="inherit" size={24} /> : null}
                                            {params.InputProps.endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                    />
                    </FormControl>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default FilterFormBloqueioEmail;