import React, { useState, useContext } from 'react';
import { Typography } from '@mui/material';
import { INumeroDaSorte } from '../../../interfaces/ISeguros';
import { AuthContext } from '../../../contexts/auth';
import Relatorio from '../../../components/common/Relatorio';

const ImportarNumeroSorte: React.FC = () => {
    const [ficha, setFicha] = useState<INumeroDaSorte[]>([]);
    const { buscarNumerosDaSorte, exportarNumerosDaSorte } = useContext(AuthContext);

    return (
        <>
            <Typography variant="h6">Importar Número da Sorte</Typography>
            <Relatorio
                busca={buscarNumerosDaSorte}
                exporta={exportarNumerosDaSorte}
                ficha={ficha}
                setFicha={setFicha}
                relatorio={"NumerosDaSorte"}
            />
        </>
    );
};

export default ImportarNumeroSorte;
