import { useLocation } from "react-router-dom";
import appRoutesNovo from "./appRoutes";
import { RouteType } from "./config";

const usePageTitle = () => {
  const location = useLocation();
  const findTitle = (routes: RouteType[], path: string): string | undefined => {
    for (let route of routes) {
      if (route.path === path) {
        return route.sidebarProps?.displayText;
      }
      if (route.child) {
        const childTitle = findTitle(route.child, path);
        if (childTitle) {
          return childTitle;
        }
      }
    }
    return undefined;
  };

  return findTitle(appRoutesNovo, location.pathname) || "GDocs";
};

export default usePageTitle;