import React from 'react';
import { Button } from '@mui/material';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';

interface PaginationProps {
  paginaAtual: number;
  totalPaginas: number;
  PaginaAnterior: () => void;
  ProximaPagina: () => void;
  IrParaPagina: (pageNumber: number) => void;
  IrParaPrimeiraPagina: () => void;
  IrParaUltimaPagina: () => void;
}

const Pagination: React.FC<PaginationProps> = ({
  paginaAtual,
  totalPaginas,
  PaginaAnterior,
  ProximaPagina,
  IrParaPagina,
  IrParaPrimeiraPagina,
  IrParaUltimaPagina
}) => {
  const RenderizarNumerosPaginas = () => {
    const numerosPaginas = [];
    const maxPaginasParaExibir = 2;
    let paginaInicial = Math.max(1, paginaAtual - maxPaginasParaExibir);
    let paginaFinal = Math.min(totalPaginas, paginaAtual + maxPaginasParaExibir);

    if (paginaAtual <= maxPaginasParaExibir) {
      paginaFinal = Math.min(paginaInicial + maxPaginasParaExibir * 2, totalPaginas);
    } else if (paginaAtual >= totalPaginas - maxPaginasParaExibir) {
      paginaInicial = Math.max(paginaFinal - maxPaginasParaExibir * 2, 1);
    }

    for (let i = paginaInicial; i <= paginaFinal; i++) {
      numerosPaginas.push(
        <Button
          key={i}
          onClick={() => IrParaPagina(i)}
          style={{ fontWeight: paginaAtual === i ? 'bold' : 'normal' }}
        >
          {i}
        </Button>
      );
    }
    return numerosPaginas;
  };

  return (
    <center>
      <div>
        <Button onClick={IrParaPrimeiraPagina} disabled={paginaAtual === 1}>
          <FirstPageIcon />
        </Button>
        <Button onClick={PaginaAnterior} disabled={paginaAtual === 1}>
          Página Anterior
        </Button>
        {RenderizarNumerosPaginas()}
        <Button onClick={ProximaPagina} disabled={paginaAtual === totalPaginas}>
          Próxima Página
        </Button>
        <Button onClick={IrParaUltimaPagina} disabled={paginaAtual === totalPaginas}>
          <LastPageIcon />
        </Button>
      </div>
    </center>
  );
};

export default Pagination;
